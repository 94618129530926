import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import BasicCrudService from "../../service/BasicCrudService";
import { Password } from "primereact/password";

const UsersList = () => {
  let resourcePlural = "users";
  let emptyUser = {
    username: "",
    password: "",
    email: "",
  };

  const [users, setUsers] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [userDialog, setUserDialog] = useState(false);
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [deleteusersDialog, setDeleteusersDialog] = useState(false);
  const [user, setUser] = useState(emptyUser);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const crudService = new BasicCrudService();

  useEffect(() => {
    crudService.get(resourcePlural).then((data) => setUsers(data));
  }, []);

  const openNew = () => {
    setUser(emptyUser);
    setSubmitted(false);
    setUserDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setUserDialog(false);
  };

  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };

  const hideDeleteusersDialog = () => {
    setDeleteusersDialog(false);
  };

  const saveUser = () => {
    setSubmitted(true);
    let _users = [...users];
    let _user = { ...user };

    if (user.id) {
      const index = findIndexById(user.id);

      _users[index] = _user;
      crudService
        .update(resourcePlural, user.id, _user)
        .then(() => {
          setUsers(_users);
          setUserDialog(false);
          setUser(emptyUser);
          setEditMode(false);
          toast.current.show({
            severity: "success",
            summary: "Succès",
            detail: "utilisateur mis à jour",
            life: 3000,
          });
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Erreur",
            detail: "Le formulaire contient des erreurs",
            life: 3000,
          });
        });
    } else {
      crudService
        .save(resourcePlural, _user)
        .then((data) => {
          _users.unshift(data);
          toast.current.show({
            severity: "success",
            summary: "Succès",
            detail: "utilisateur enregistré",
            life: 3000,
          });
          setUsers(_users);
          setUserDialog(false);
          setUser(emptyUser);
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Erreur",
            detail: "Le formulaire contient des erreurs",
            life: 3000,
          });
        });
    }
  };

  const editUser = (user) => {
    setEditMode(true);
    setUser({ ...user });
    setUserDialog(true);
  };

  const confirmDeleteUser = (user) => {
    setUser(user);
    setDeleteUserDialog(true);
  };

  const deleteUser = () => {
    crudService
      .delete(resourcePlural, user.id)
      .then((data) => {
        let _users = users.filter((val) => val.id !== user.id);
        setUsers(_users);
        setDeleteUserDialog(false);
        setUser(emptyUser);
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Utilisateur supprimé",
          life: 3000,
        });
      })
      .catch((err) => {
        setUser(emptyUser);
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Impossible de supprimer l'urilisateur",
          life: 3000,
        });
      });
  };

  const findIndexById = (id) => {
    return users.findIndex((item) => item.id === id);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteusersDialog(true);
  };

  const deleteselectedUsers = () => {
    let _users = users.filter((val) => !selectedUsers.includes(val));
    setUsers(_users);
    setDeleteusersDialog(false);
    setSelectedUsers(null);
    toast.current.show({
      severity: "success",
      summary: "Succès",
      detail: "utilisateurs supprimés",
      life: 3000,
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _user = { ...user };
    _user[`${name}`] = val;

    setUser(_user);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Supprimer"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedUsers || !selectedUsers.length}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exporter"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editUser(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => confirmDeleteUser(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Gestion des utilisateurs</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const userDialogFooter = (
    <>
      <Button
        label="Annuler"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Valider"
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveUser}
      />
    </>
  );
  const deleteUserDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteUserDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteUser}
      />
    </>
  );
  const deleteUsersDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteusersDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteselectedUsers}
      />
    </>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={users}
            selection={selectedUsers}
            onSelectionChange={(e) => setSelectedUsers(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} éléments"
            globalFilter={globalFilter}
            emptyMessage="Aucun utilisateur trouvé"
            header={header}
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple"></Column>
            <Column
              filter
              filterPlaceholder="ddddddd"
              field="username"
              header="Nom d'utilisateur"
              sortable
            ></Column>
            <Column field="email" header="Adresse email" sortable></Column>
            <Column
              field="createdAt"
              header="Date de création"
              sortable
            ></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>

          <Dialog
            visible={userDialog}
            style={{ width: "450px" }}
            header="Détails utilisateur"
            modal
            className="p-fluid"
            footer={userDialogFooter}
            onHide={hideDialog}
          >
            <div className="field">
              <label htmlFor="username">Nom d'utilisateur</label>
              <InputText
                id="username"
                value={user.username}
                onChange={(e) => onInputChange(e, "username")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !user.username,
                })}
              />
              {submitted && !user.username && (
                <small className="p-invalid">Ce champ est obligatoire.</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="email">Adresse email</label>
              <InputText
                id="description"
                value={user.email}
                onChange={(e) => onInputChange(e, "email")}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid": submitted && !user.email,
                })}
              />
              {submitted && !user.email && (
                <small className="p-invalid">Ce champ est obligatoire.</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="password">Mot de passe</label>
              <Password
                id="description"
                value={user.description}
                onChange={(e) => onInputChange(e, "password")}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid": submitted && !user.password && !editMode,
                })}
              />
              {submitted && !user.password && !editMode && (
                <small className="p-invalid">Ce champ est obligatoire.</small>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteUserDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteUserDialogFooter}
            onHide={hideDeleteUserDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {user && (
                <span>
                  Voulez vous vraiment supprimer <b>{user.username}</b>?
                </span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteusersDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteUsersDialogFooter}
            onHide={hideDeleteusersDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {user && (
                <span>
                  Voulez vous vraiment supprimer les utilisateurs sélectionnés?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
