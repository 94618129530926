import React, { useEffect, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { Button, classNames, Toast } from "primereact";

import * as Yup from "yup";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import BasicCrudService from "../../service/BasicCrudService";
import { INTRANT_SUPPLIER_URL } from "../../service/constant.service";
import { useFormik } from "formik";

const IntrantSupplierStatsList = () => {
  const [instrantSupplierStat, setInstrantSupplierStat] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [intrants, setIntrants] = React.useState([]);
  const [selectedEntities, setSelectedEntities] = React.useState(null);
  const [suppliers, setSuppliers] = React.useState([]);
  const [deleteManyDialog, setDeleteManyDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [entitie, setEntitie] = React.useState({
    year: "",
    supplier: "",
    intrant: "",
    total_expected_quantity: "",
    local_quantity: "",
    expected_imported_quantity: "",
    average_price: "",
    sold_quantity: "",
  });
  const toast = useRef(null);

  const dt = useRef(null);

  const flag = useRef(true);
  const displayDialog = () => {
    setEntitie({
      year: "",
      supplier: "",
      intrant: "",
      total_expected_quantity: "",
      local_quantity: "",
      expected_imported_quantity: "",
      average_price: "",
      sold_quantity: "",
    });
    setDialog(true);
  };
  const hideDialog = () => {
    setDialog(false);
    setIsEdit(false);
  };

  useEffect(() => {
    if (flag.current) {
      (async () => fetchInstrantSupStat())();
      (async () => fetchIntrants())();
      (async () => fetchSuppliers())();
    }
  }, []);

  const deleteEntitiy = () => {
    const crudService = new BasicCrudService();
    crudService
      .delete(INTRANT_SUPPLIER_URL, entitie?.id)
      .then((res) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Les données ont été supprimées",
          life: 3000,
        });
        fetchInstrantSupStat();
        setDeleteDialog(false);
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Une erreur s'est produite",
          life: 3000,
        });
        setDeleteDialog(false);
      });
  };

  const deleteSelectedEntities = () => {
    let _entities = instrantSupplierStat.filter(
        (val) => !selectedEntities.includes(val.id)
      ),
      _selectedEntities = null;
    setInstrantSupplierStat(_entities);
    setDeleteManyDialog(false);
    setSelectedEntities(_selectedEntities);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Les données ont été supprimées",
      life: 3000,
    });
  };

  const deleteEntitiyDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteDialog(false)}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteEntitiy}
      />
    </>
  );
  const deleteEntitiesDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteManyDialog(false)}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedEntities}
      />
    </>
  );
  const fetchIntrants = () => {
    const crudService = new BasicCrudService();
    crudService
      .get("/intrants")
      .then((data) => {
        setIntrants(data?.data);
      })
      .catch((error) => {
      });
  };

  const fetchSuppliers = () => {
    const crudService = new BasicCrudService();
    crudService
      .get("/actors", "filters[recipientType][category]=SUPPLIER")
      .then((data) => {
        setSuppliers(data?.data);
      })
      .catch((error) => {
      });
  };

  const fetchInstrantSupStat = () => {
    const crudService = new BasicCrudService();
    crudService
      .get(INTRANT_SUPPLIER_URL)
      .then((data) => {
        setInstrantSupplierStat(data?.data);
      })
      .catch((error) => {
      });
  };

  let emptyEntity = {
    year: "",
    supplier: "",
    intrant: "",
    total_expected_quantity: "",
    local_quantity: "",
    expected_imported_quantity: "",
    average_price: "",
    sold_quantity: "",
  };

  const formik = useFormik({
    initialValues: entitie,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object({
      year: Yup.number("Entrée invalide").required("Ce champ est obligatoire"),
      supplier: Yup.number("Entrée invalide").required(
        "Ce champ est obligatoire"
      ),
      intrant: Yup.number("Entrée invalide").required(
        "Ce champ est obligatoire"
      ),
      total_expected_quantity: Yup.number("Entrée invalide").required(
        "Ce champ est obligatoire"
      ),
      local_quantity: Yup.number("Entrée invalide").required(
        "Ce champ est obligatoire"
      ),
      expected_imported_quantity: Yup.number("Entrée invalide").required(
        "Ce champ est obligatoire"
      ),
      average_price: Yup.number("Entrée invalide").required(
        "Ce champ est obligatoire"
      ),
      sold_quantity: Yup.number("Entrée invalide").required(
        "Ce champ est obligatoire"
      ),
    }),
    onSubmit: (values) => {
      const crudService = new BasicCrudService();
      if (isEdit) {
        crudService
          .update(INTRANT_SUPPLIER_URL, values?.id, values)
          .then((res) => {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Les données ont été mises à jour",
              life: 3000,
            });
            fetchInstrantSupStat();
            setDialog(false);
            setIsEdit(false);
          })
          .catch((err) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Une erreur s'est produite",
              life: 3000,
            });
          });
      } else {
        crudService
          .save(INTRANT_SUPPLIER_URL, values)
          .then((data) => {
            fetchInstrantSupStat();
            hideDialog();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Statistiques fournisseur intrant enregistré",
              life: 3000,
            });
            setEntitie(emptyEntity);
            formik.resetForm();
          })
          .catch((error) => {
          });
      }
    },
  });

  const onInputChange = (e, name) => {
    const val = e.target.value;
    formik.setFieldValue(name, val);
  };

  const suppliersToBeSelected =
    suppliers.length !== 0
      ? suppliers.map((entity) => {
          return {
            label: entity.attributes?.org_name,
            value: entity.id,
          };
        })
      : [];

  const intrantsToBeSelected =
    intrants.length !== 0
      ? intrants.map((entity) => {
          return {
            label: entity.attributes?.label,
            value: entity.id,
          };
        })
      : [];

  const editEntitiy = (entitySelected) => {
    setEntitie({
      ...entitySelected.attributes,
      id: entitySelected.id,
      intrant: entitySelected.attributes?.intrant?.data?.id,
      supplier: entitySelected.attributes?.supplier?.data?.id,
    });
    setDialog(true);
    setIsEdit(true);
  };
  const confirmDeleteEntitiy = (entity) => {
    setEntitie(entity);
    setDeleteDialog(true);
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exporter"
          icon="pi pi-upload"
          className="p-button-help"
        />
      </React.Fragment>
    );
  };
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={displayDialog}
          />
          <Button
            label="Supprimer"
            icon="pi pi-trash"
            className="p-button-danger"
            disabled={!selectedEntities || !selectedEntities.length}
            onClick={() => setDeleteManyDialog(true)}
          />
        </div>
      </React.Fragment>
    );
  };
  const dialogFooter = (
    <>
      <Button
        label="Annuler"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label={isEdit ? "Editer" : "Valider"}
        icon={isEdit ? "pi pi-pencil" : "pi pi-check"}
        className="p-button-text"
        onClick={() => formik.submitForm()}
      />
    </>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editEntitiy(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => confirmDeleteEntitiy(rowData)}
        />
      </div>
    );
  };
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            dataKey="id"
            paginator
            ref={dt}
            onSelectionChange={(e) => setSelectedEntities(e.value)}
            selection={selectedEntities}
            rows={10}
            value={instrantSupplierStat}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} éléments"
            emptyMessage="Aucune donnée trouvée"
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple"></Column>

            <Column
              field="year"
              header="Année"
              body={(data) => data?.attributes?.year}
              sortable
            ></Column>
            <Column
              field="supplier"
              header="Fournisseur"
              body={(data) =>
                data?.attributes?.supplier?.data?.attributes?.org_name
              }
              sortable
            ></Column>
            <Column
              field="intrant"
              header="Intrant"
              body={(data) =>
                data?.attributes?.intrant?.data?.attributes?.label
              }
              sortable
            ></Column>
            <Column
              field="total_quantity"
              header="Quantité Totale"
              body={(data) => data?.attributes?.total_expected_quantity}
              sortable
            ></Column>

            <Column
              field="local_quantity"
              header="Quantité Locale"
              body={(data) => data?.attributes?.local_quantity}
              sortable
            ></Column>
            <Column
              field="imported_quantity"
              header="Quantité Importée"
              body={(data) => data?.attributes?.expected_imported_quantity}
            ></Column>
            <Column
              field="average_price"
              body={(data) => data?.attributes?.average_price}
              header="Prix Moyen"
              sortable
            ></Column>
            <Column
              field="sold_quantity"
              body={(data) => data?.attributes?.sold_quantity}
              header="Quantité Vendue"
              sortable
            ></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>
          <Dialog
            visible={dialog}
            style={{ width: "450px" }}
            header="Détails"
            modal
            className="p-fluid"
            footer={dialogFooter}
            onHide={hideDialog}
          >
            <div className="field">
              <label htmlFor="year">Année</label>
              <InputText
                id="year"
                name="year"
                value={formik.values.year}
                onChange={(e) => onInputChange(e, "year")}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid": formik.errors.year && formik.touched.year,
                })}
              />
              {formik.errors.year && formik.touched.year && (
                <small className="p-error">{formik.errors.year}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="supplier">Fournisseur</label>
              <Dropdown
                value={formik.values.supplier}
                options={suppliersToBeSelected}
                onChange={(e) => onInputChange(e, "supplier")}
                placeholder="Sélectionner un fournisseur"
                className={classNames({
                  "p-invalid":
                    formik.errors.supplier && formik.touched.supplier,
                })}
              />
              {formik.errors.supplier && formik.touched.supplier && (
                <small className="p-error">{formik.errors.supplier}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="intrant">Intrant</label>
              <Dropdown
                value={formik.values.intrant}
                options={intrantsToBeSelected}
                onChange={(e) => onInputChange(e, "intrant")}
                placeholder="Sélectionner un intrant"
                className={classNames({
                  "p-invalid": formik.errors.intrant && formik.touched.intrant,
                })}
              />
              {formik.errors.intrant && formik.touched.intrant && (
                <small className="p-error">{formik.errors.intrant}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="total_quantity">Quantité Totale</label>
              <InputText
                id="total_expected_quantity"
                value={formik.values.total_expected_quantity}
                onChange={(e) => onInputChange(e, "total_expected_quantity")}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid":
                    formik.errors.total_expected_quantity &&
                    formik.touched.total_expected_quantity,
                })}
              />
              {formik.errors.total_expected_quantity &&
                formik.touched.total_expected_quantity && (
                  <small className="p-error">
                    {formik.errors.total_expected_quantity}
                  </small>
                )}
            </div>
            <div className="field">
              <label htmlFor="local_quantity">Quantité Locale</label>
              <InputText
                id="local_quantity"
                value={formik.values.local_quantity}
                onChange={(e) => onInputChange(e, "local_quantity")}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid":
                    formik.errors.local_quantity &&
                    formik.touched.local_quantity,
                })}
              />
              {formik.errors.local_quantity &&
                formik.touched.local_quantity && (
                  <small className="p-error">
                    {formik.errors.local_quantity}
                  </small>
                )}
            </div>
            <div className="field">
              <label htmlFor="imported_quantity">Quantité Importée</label>
              <InputText
                id="imported_quantity"
                value={formik.values.expected_imported_quantity}
                onChange={(e) => onInputChange(e, "expected_imported_quantity")}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid":
                    formik.errors.expected_imported_quantity &&
                    formik.touched.expected_imported_quantity,
                })}
              />
              {formik.errors.imported_quantity &&
                formik.touched.imported_quantity && (
                  <small className="p-error">
                    {formik.errors.imported_quantity}
                  </small>
                )}
            </div>
            <div className="field">
              <label htmlFor="average_price">Prix Moyen</label>
              <InputText
                id="average_price"
                value={formik.values.average_price}
                onChange={(e) => onInputChange(e, "average_price")}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid":
                    formik.errors.average_price && formik.touched.average_price,
                })}
              />
              {formik.errors.average_price && formik.touched.average_price && (
                <small className="p-error">{formik.errors.average_price}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="sold_quantity">Quantité Vendue</label>
              <InputText
                id="sold_quantity"
                value={formik.values.sold_quantity}
                onChange={(e) => onInputChange(e, "sold_quantity")}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid":
                    formik.errors.sold_quantity && formik.touched.sold_quantity,
                })}
              />
              {formik.errors.sold_quantity && formik.touched.sold_quantity && (
                <small className="p-error">{formik.errors.sold_quantity}</small>
              )}
            </div>
          </Dialog>
          <Dialog
            visible={deleteDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteEntitiyDialogFooter}
            onHide={() => setDeleteDialog(false)}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entitie && (
                <span>
                  Voulez vous vraiment supprimer <b>{entitie?.label}</b>?
                </span>
              )}
            </div>
          </Dialog>
          <Dialog
            visible={deleteManyDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteEntitiesDialogFooter}
            onHide={() => setDeleteManyDialog(false)}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entitie && (
                <span>
                  Voulez vous vraiment supprimer les Elements sélectionnées?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default IntrantSupplierStatsList;
