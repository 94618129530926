import { Routes, Route } from "react-router-dom";
import PostsList from "./PostsList";

export default function Posts() {
  return (
    <Routes>
      <Route path="/" element={<PostsList />} />
    </Routes>
  );
}
