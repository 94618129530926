const RecipientCategory = {
    OTHER: 'Autre',
    PRODUCTOR: 'Producteur ou agriculteur',
    MICRO_FINANCE: 'Microfinance et assurance',
    SUPPLIER: "Fournisseur d'intrants",
    TRANSFORMATOR: 'Transformateur',
    EDUCATION_AND_RESEARCH: 'Education et recherche',
    GOVERNMENT: 'Gouvernement',
    TRANSPORTER: 'Transporteur',
    CIVIL_SOCIETY: 'Société civile',
    SELLERS_AND_BUYERS: 'Commercçants et acheteurs'
};

export default RecipientCategory;