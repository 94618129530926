import crudService from "../service";

export async function getResources(resourcePlurals, onSuccess) {
    try {
        const tasks = await Promise.all([
            ...resourcePlurals.map((resourcePlural) => {
                return crudService.get(`${resourcePlural}`);
            }),
        ]);
        onSuccess(tasks);
    } catch (error) {
        console.error("An error occured while fetching entities: ", error);
    }
}

export function entitiesToSelectItems(entities, label = "name") {
    return entities.length !== 0 ?
        entities.map((entity) => {
            return {
                label: entity.attributes[label],
                value: entity.id,
            };
        }) :
        [];
}