import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import BasicCrudService from '../../service/BasicCrudService';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const AgeRangesList = () => {
    const resourcePlural = 'age-ranges';
    let emptyEntity = {
        min: '',
        max: ''
    };

    const [entities, setEntities] = useState(null);
    const [enityDialog, setEntityDialog] = useState(false);
    const [deleteEntityDialog, setDeleteEntityDialog] = useState(false);
    const [deleteEntitiesDialog, setDeleteEntitiesDialog] = useState(false);
    const [entity, setEntity] = useState(emptyEntity);
    const [selectedEntities, setSelectedEntities] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const crudService = new BasicCrudService();

    const formik = useFormik({
        initialValues: entity,
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: Yup.object({
            min: Yup.number().required(),
            max: Yup.number().moreThan(Yup.ref('min')).required()
        }),
        onSubmit: (values) => {
            saveEntity(values);
        }
    })

    useEffect(() => {
        crudService.get(`${resourcePlural}/?populate=country`).then((data) => {
            setEntities(data.data);
        });
    }, []);

    const openNew = () => {
        setEntity(emptyEntity);
        setEntityDialog(true);
    };

    const hideDialog = () => {
        setEntityDialog(false);
    };

    const hideDeleteEntityDialog = () => {
        setDeleteEntityDialog(false);
    };

    const hideDeletecountriesDialog = () => {
        setDeleteEntitiesDialog(false);
    };

    const saveEntity = values => {
        let _entities = [...entities];
        let _entity = { ...values };

        if (entity.id) {
            const index = findIndexById(entity.id);

            crudService
                .update(resourcePlural, entity.id, _entity)
                .then(({ data }) => {
                    _entities[index] = data;
                    setEntities(_entities);
                    setEntityDialog(false);
                    setEntity(emptyEntity);
                    toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Tranche mise à jour', life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Le formulaire contient des erreurs', life: 3000 }); 
                });
        } else {
            crudService
                .save(resourcePlural, _entity)
                .then(data => {
                    _entities.unshift(data.data);
                    toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Tranche enregistrée', life: 3000 });
                    setEntities(_entities);
                    setEntityDialog(false);
                    setEntity(emptyEntity);
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Le formulaire contient des erreurs', life: 3000 }); 
                });
        }
    };

    const editEntitiy = (entitySelected) => {
        setEntity({ ...entitySelected.attributes, id: entitySelected.id });
        setEntityDialog(true);
    };

    const confirmDeleteEntitiy = (entity) => {
        setEntity(entity);
        setDeleteEntityDialog(true);
    };

    const deleteEntitiy = () => {
        crudService
            .delete(resourcePlural, entity.id)
            .then(data => {
                let _entities = entities.filter((val) => val.id !== entity.id);
                setEntities(_entities);
                setDeleteEntityDialog(false);
                setEntity(emptyEntity);
                toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Tranche supprimée', life: 3000 });
            })
            .catch(err => {
                setEntity(emptyEntity);
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Impossible de supprimer la Tranche", life: 3000 });
            });
    };

    const findIndexById = (id) => {
        return entities.findIndex(item => item.id === id);
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteEntitiesDialog(true);
    };

    const deleteselectedEntities = () => {
        let _entities = entities.filter((val) => !selectedEntities.includes(val));
        setEntities(_entities);
        setDeleteEntitiesDialog(false);
        setSelectedEntities(null);
        toast.current.show({ severity: 'success', summary: 'Succès', detail: ' supprimés', life: 3000 });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedEntities || !selectedEntities.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exporter" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };
    

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editEntitiy(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteEntitiy(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gestion des tranches d'age</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const userDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={() => formik.submitForm()} />
        </>
    );
    const deleteEntitiyDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEntityDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteEntitiy} />
        </>
    );
    const deleteEntitiesDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeletecountriesDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteselectedEntities} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={entities}
                        selection={selectedEntities}
                        onSelectionChange={(e) => setSelectedEntities(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} éléments"
                        globalFilter={globalFilter}
                        emptyMessage="Aucune donnée trouvée"
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple"></Column>
                        <Column field="min" body={data => data.attributes.min} header="Minimum" sortable></Column>
                        <Column field="max" body={data => data.attributes.max} header="Maximum" sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={enityDialog} style={{ width: '450px' }} header="Détails Tranche" modal className="p-fluid" footer={userDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="min">Age minimum</label>
                            <InputText id="min" value={formik.values.min} onChange={formik.handleChange} required rows={3} cols={20}  className={classNames({ 'p-invalid': formik.errors.min && formik.touched.min})} />
                            {formik.errors.min && formik.touched.min && <small className="p-invalid">{formik.errors.min}</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="max">Age maximum</label>
                            <InputText id="max" value={formik.values.max} onChange={formik.handleChange} required rows={3} cols={20}  className={classNames({ 'p-invalid': formik.errors.max && formik.touched.max})} />
                            {formik.errors.max && formik.touched.max && <small className="p-invalid">{formik.errors.max}</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteEntityDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEntitiyDialogFooter} onHide={hideDeleteEntityDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {entity && (
                                <span>
                                    Voulez vous vraiment supprimer <b>{entity.username}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteEntitiesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEntitiesDialogFooter} onHide={hideDeletecountriesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {entity && <span>Voulez vous vraiment supprimer les Tranche sélectionnées?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default AgeRangesList;
