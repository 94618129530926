import React, { useState, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Password } from "primereact/password";
import Spinner from "../components/Spinner/Spinner";
import axios from "axios";
import authContext from "../context/authContext";
import { URL } from '../service/constant.service';
const axiosInstance = axios.create();

export const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useContext(authContext);

  const handleConnection = (e) => {
    setLoading(true);
    //A changer
    axiosInstance
      .post(`${URL}/auth/local`, {
        password,
        identifier: username,
      })
      .then(({ data }) => {
        setLoading(false);
        localStorage.setItem('userId', data.user.id);
        localStorage.setItem('username', data.user.username);
        login(data.jwt);
        navigate("/");
      })
      .catch((err) => {
        setLoading(false);
        alert("Identifiants invalides, veuillez réessayer");
      });
  };

  return (
    <div className="login-body">
      <div className="login-panel"> </div>
      <div className="login-content">
        <img src="assets/layout/images/logo-black.png" alt="babylon-layout" />
        <h1>
          <span> Administration de la plateforme de numérisation agricole </span>
        </h1>
        <div className="login-input-wrapper">
          <InputText
            placeholder="Nom d'utilisateur"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <i className="pi pi-user"> </i>
        </div>
        <div className="login-input-wrapper">
          <Password
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            feedback={false}
          />
          <i className="pi pi-lock"> </i>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <Button
            disabled={!username || !password}
            label="Connexion"
            onClick={handleConnection}
          />
        )}
      </div>
    </div>
  );
};
