import { Button } from "primereact/button";

export default function EntityActions({ actions }) {
  return (
    <div className="actions">
      {actions.map((action, key) => (
        <Button
          key={key}
          icon={action.icon}
          className={action.className}
          onClick={action.onClick}
        />
      ))}
    </div>
  );
}
