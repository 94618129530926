import React, { useState, useRef, useEffect, useContext } from "react";
import { classNames } from "primereact/utils";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";

import AppTopbar from "./AppTopbar";
import AppFooter from "./AppFooter";
import AppConfig from "./AppConfig";
import AppMenu from "./AppMenu";
import AppBreadcrumb from "./AppBreadcrumb";
import AppInlineProfile from "./AppInlineProfile";

import Dashboard from "./components/Dashboard";
import UsersPage from "./pages/users";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import PrivateRoute from "./components/util/PrivateRoute";
import authContext from "./context/authContext";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.scss";
import "./style.css";
import { Login } from "./pages/Login";
import CountriesPage from "./pages/countries";
import PIsPage from "./pages/pis";
import AgeRangesPage from "./pages/ageRanges";
import RecipientTypesPage from "./pages/recipientTypes";
import ProductList from "./pages/products/ProductList";
import Actors from "./pages/actors";
import Posts from "./pages/posts";

import { breadcrumb, menu } from "./settings/ui/admin";
import PiZoneList from "./pages/pi_zones/PiZoneList";
import CommentsPage from "./pages/comments";
import IntrantsList from "./pages/intrants/IntrantsList";
import ProductorFarmerStat from "./pages/productor_farmer_stat/ProductorFarmerStat";
import IntrantSupplierStatsList from "./pages/IntrantSupplierStats/IntrantSupplierStatsList";
import EducationResearchStatsList from "./pages/EducationResearchStats/EducationResearchStatsList";
import MicroFinanceListe from "./pages/micro-finance/MicroFinanceListe";
import AssuranceStatsList from "./pages/assuranceStats/AssuranceStatsList";
import GovernementStatsList from "./pages/GovernementStats/GovernementStatsList";
import CivilSocietyStatsList from "./pages/CivilSocietyStats/CivilSocietyStatsList";

const App = () => {
  const { isAuthenticated } = useContext(authContext);
  const [menuActive, setMenuActive] = useState(false);
  const [menuMode, setMenuMode] = useState("static");
  const [darkMenu, setDarkMenu] = useState(true);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [topbarMenuActive, setTopbarMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] =
    useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [inlineMenuActive, setInlineMenuActive] = useState(false);
  const [profileMode, setProfileMode] = useState("popup");
  const [configActive, setConfigActive] = useState(false);
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(false);
  const copyTooltipRef = useRef();
  const location = useLocation();

  const navigate = useNavigate();

  let menuClick = false;
  let configClick = false;
  let topbarItemClick = false;
  let inlineMenuClick = false;

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  let meta = breadcrumb.find((obj) => {
    return obj.path === location.pathname;
  });

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRippleChange = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onMenuModeChange = (e) => {
    setMenuMode(e.value);
    setStaticMenuDesktopInactive(false);
    setOverlayMenuActive(false);

    if (e.value === "horizontal") {
      setProfileMode("popup");
    }
  };

  const onMenuColorChange = (e) => {
    setDarkMenu(e.value);
  };

  const onProfileChange = (e) => {
    setProfileMode(e.value);
  };

  const onDocumentClick = () => {
    if (!topbarItemClick) {
      setActiveTopbarItem(null);
      setTopbarMenuActive(false);
    }

    if (!menuClick) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
      }
      hideOverlayMenu();
    }

    if (
      !inlineMenuClick &&
      profileMode === "inline" &&
      isSlim() &&
      !isMobile()
    ) {
      setInlineMenuActive(false);
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    inlineMenuClick = false;
    configClick = false;
    topbarItemClick = false;
    menuClick = false;
  };

  const onMenuitemClick = (event) => {
    if (!event.item.items) {
      hideOverlayMenu();

      if (isSlim() || isHorizontal()) {
        setMenuActive(false);
      }
    }
  };

  const onRootMenuitemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const onMenuClick = () => {
    menuClick = true;

    if (inlineMenuActive && !inlineMenuClick) {
      setInlineMenuActive(false);
    }
  };

  const isMenuVisible = () => {
    if (isDesktop()) {
      if (menuMode === "static") return !staticMenuDesktopInactive;
      else if (menuMode === "overlay") return overlayMenuActive;
      else return true;
    } else {
      return true;
    }
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;
    setTopbarMenuActive(false);

    if (isOverlay() && !isMobile()) {
      setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
    } else {
      if (isDesktop()) {
        setStaticMenuDesktopInactive(
          (prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive
        );
      } else {
        setStaticMenuMobileActive(
          (prevStaticMenuMobileActive) => !prevStaticMenuMobileActive
        );
      }
    }

    event.preventDefault();
  };

  const onProfileButtonClick = (event) => {
    setInlineMenuActive((prevInlineMenuActive) => !prevInlineMenuActive);
    inlineMenuClick = true;

    if (isSlim() || isHorizontal()) {
      setMenuActive(false);
    }
  };

  const onTopbarMenuButtonClick = (event) => {
    topbarItemClick = true;
    setTopbarMenuActive((prevTopbarMenuActive) => !prevTopbarMenuActive);

    hideOverlayMenu();

    event.preventDefault();
  };

  const onTopbarItemClick = (event, item) => {
    topbarItemClick = true;

    if (activeTopbarItem === item) {
      setActiveTopbarItem(null);
    } else {
      setActiveTopbarItem(item);
    }

    event.preventDefault();
  };

  const onConfigClick = () => {
    configClick = true;
  };

  const onConfigButtonClick = () => {
    setConfigActive((prevConfigActive) => !prevConfigActive);
    configClick = true;
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const isDesktop = () => {
    return window.innerWidth > 896;
  };

  const isMobile = () => {
    return window.innerWidth <= 896;
  };

  const isOverlay = () => {
    return menuMode === "overlay";
  };

  const isHorizontal = () => {
    return menuMode === "horizontal";
  };

  const isSlim = () => {
    return menuMode === "slim";
  };

  const isStatic = () => {
    return menuMode === "static";
  };

  const hasInlineProfile = profileMode === "inline" && !isHorizontal();

  const containerClassName = classNames("layout-wrapper", {
    "layout-static": isStatic(),
    "layout-overlay": isOverlay(),
    "layout-overlay-active": overlayMenuActive,
    "layout-horizontal": isHorizontal(),
    "layout-slim": isSlim(),
    "layout-static-inactive": staticMenuDesktopInactive,
    "layout-mobile-active": staticMenuMobileActive,
    "layout-menu-dark": darkMenu,
    "layout-menu-light": !darkMenu,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": !ripple,
  });

  const menuContainerClassName = classNames("layout-menu-container", {
    "layout-menu-container-inactive": !isMenuVisible(),
  });

  return (
    <div className={containerClassName} onClick={onDocumentClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />
      <AppTopbar
        topbarMenuActive={topbarMenuActive}
        activeTopbarItem={activeTopbarItem}
        onMenuButtonClick={onMenuButtonClick}
        onTopbarMenuButtonClick={onTopbarMenuButtonClick}
        onTopbarItemClick={onTopbarItemClick}
        isHorizontal={isHorizontal()}
        profileMode={profileMode}
        isMobile={isMobile}
      />
      <div className={menuContainerClassName} onClick={onMenuClick}>
        <div className="layout-menu-logo">
          <button className="p-link" onClick={() => navigate("/")}>
            <img
              id="layout-menu-logo"
              src="assets/layout/images/logo-white.png"
              library="babylon-layout"
              alt="babylon-logo"
            />
          </button>
        </div>
        <div className="layout-menu-wrapper">
          <div className="menu-scroll-content">
            {hasInlineProfile && (
              <AppInlineProfile
                inlineMenuActive={inlineMenuActive}
                onProfileButtonClick={onProfileButtonClick}
              />
            )}
            <AppMenu
              model={menu}
              menuMode={menuMode}
              active={menuActive}
              onMenuitemClick={onMenuitemClick}
              onRootMenuitemClick={onRootMenuitemClick}
            />
          </div>
        </div>
      </div>
      <div className="layout-main">
        <AppBreadcrumb meta={meta} />
        <div className="layout-content">
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <UsersPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/countries/*"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <CountriesPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/pis"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <PIsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/products"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <ProductList />
                </PrivateRoute>
              }
            />
            <Route
              path="/intrants"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <IntrantsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/intrant-suppliers-stats"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <IntrantSupplierStatsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/age-ranges/*"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <AgeRangesPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/recipient-types/*"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <RecipientTypesPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/actors/*"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <Actors />
                </PrivateRoute>
              }
            />
            <Route
              path="/comments/*"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <CommentsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/posts/*"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <Posts />
                </PrivateRoute>
              }
            />
            <Route
              path="/productors-farmers/*"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <ProductorFarmerStat />
                </PrivateRoute>
              }
            />
            <Route
              path="/education-research-stats/*"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <EducationResearchStatsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/micro-finances/*"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <MicroFinanceListe />
                </PrivateRoute>
              }
            />

            <Route
              path="/assurance-stats/*"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <AssuranceStatsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/pi_zones/*"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <PiZoneList />
                </PrivateRoute>
              }
            />
            <Route
              path="/governments/*"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <GovernementStatsList />
                </PrivateRoute>
              }
            />
             <Route
              path="/civil-society/*"
              element={
                <PrivateRoute isAllowed={isAuthenticated}>
                  <CivilSocietyStatsList />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
        <AppFooter />
      </div>
      <AppConfig
        configActive={configActive}
        menuMode={menuMode}
        onMenuModeChange={onMenuModeChange}
        isDarkMenu={darkMenu}
        onMenuColorChange={onMenuColorChange}
        profileMode={profileMode}
        onProfileChange={onProfileChange}
        onConfigClick={onConfigClick}
        onConfigButtonClick={onConfigButtonClick}
        rippleActive={ripple}
        onRippleChange={onRippleChange}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
      ></AppConfig>
      {staticMenuMobileActive && <div className="layout-mask"> </div>}
    </div>
  );
};

export default App;
