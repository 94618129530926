export function clearEmptyFields(fields) {
  Object.keys(fields).forEach((key) => {
    if (!fields[key]) {
      delete fields[key];
    }
  });
  return fields;
}
//put util use all compoment

const booleanChecker = (data) => {
  if (typeof data === "boolean") {
    return data ? "Oui" : "Non";
  }
};
const RemoveDataEmpty = (payload) => {
  return Object.entries(payload).reduce(
    (a, [k, v]) => (v || v === false ? ((a[k] = v), a) : a),
    {}
  );
  // return Object.fromEntries(
  //   Object.entries(payload).filter(([_, v]) => v !== null)
  // );
};

export const utils = {
  booleanChecker,
  RemoveDataEmpty,
};

export function entitiesToSelectItems(entities, label = "name") {
  return entities.length !== 0
    ? entities.map((entity) => {
        return {
          label: entity.attributes[label],
          value: entity.id,
        };
      })
    : [];
}

export function generateYears() {
  let years = [];

  for (let year = 2000; year < 2100; year++) {
    years.push({
      label: year,
      value: year
    })
  }
  return years;
}