import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";

export default function FormField({ label, name, placeholder, formik }) {
  const error = formik.errors[name] && formik.touched[name];
  return (
    <div className="field">
      <label htmlFor="code"> {label} </label>
      <InputText
        id={name}
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        placeholder={placeholder}
        className={classNames({
          "p-invalid": error,
        })}
      />
      {error && <small className="p-error"> Ce champ est obligatoire. </small>}
    </div>
  );
}
