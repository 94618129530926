import React, { useContext } from 'react';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';
import authContext from './context/authContext';

const AppTopbar = (props) => {
    const { logout } = useContext(authContext);
    const navigate = useNavigate();
    const topbarMenuClassName = classNames('topbar-menu fadeInDown', { 'topbar-menu-visible': props.topbarMenuActive });
    const profileItemClassName = classNames('user-profile', { 'active-topmenuitem': props.activeTopbarItem === 'profile' });

    const isProfilePopup = props.profileMode === 'popup' || props.isHorizontal;

    return (
        <div className="layout-topbar">
            <button className="layout-topbar-logo p-link" onClick={() => navigate('/')}>
                <img id="layout-topbar-logo" src="assets/layout/images/logo-white.png" alt="babylon-layout" />
            </button>

            <button className="layout-menu-button p-link" onClick={props.onMenuButtonClick}>
                <i className="pi pi-bars"></i>
            </button>

            <button id="topbar-menu-button" className="p-link" onClick={props.onTopbarMenuButtonClick}>
                <i className="pi pi-ellipsis-v"></i>
            </button>

            <ul className={topbarMenuClassName}>
                {isProfilePopup && (
                    <li className={profileItemClassName}>
                        <button className="p-link" onClick={(e) => props.onTopbarItemClick(e, 'profile')}>
                            <p>
                                {localStorage.getItem('username')} 
                            </p>
                        </button>

                        <ul className={classNames({ fadeInDown: !props.isMobile() })}>
                            <li role="menuitem">
                                <button className="p-link">
                                    <i className="pi pi-user"></i>
                                    <span>Profil</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button className="p-link" onClick={() => {
                                    logout();
                                    navigate('/');
                                }}>
                                    <i className="pi pi-cog"></i>
                                    <span>Déconnexion</span>
                                </button>
                            </li>
                        </ul>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default AppTopbar;
