import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as Yup from "yup";
import DeleteEntitiesDialog from "../../components/DeleteEntitiesDialog/DeleteEntitiesDialog";
import DeleteEntityDialog from "../../components/DeleteEntityDialog/DeleteEntityDialog";
import EntityActions from "../../components/EntityActions/EntityActions";
import FormField from "../../components/FormFields/FormField/FormField";
import Header from "../../components/Header/Header";
import BasicCrudService from "../../service/BasicCrudService";
import { clearEmptyFields, generateYears } from "../../utils";
import DropdownField from "../../components/FormFields/DropdownField/DropdownField";

const emptyEntity = {
  name: "",
  responsible_identity: "",
  responsible_address: "",
  responsible_phone: "",
  responsible_mail: "",
  pi: "",
};
export default function ProductorFarmerStat() {
  const resourcePlural = "productor-farmer-stats";

  const [entities, setEntities] = useState([]);
  const [pis, setPis] = useState([]);
  const [entityDialogIsOpen, setEntityDialogIsOpen] = useState(false);
  const [deleteEntityDialogIsOpen, setDeleteEntityDialogIsOpen] =
    useState(false);
  const [deleteEntitiesDialogIsOpen, setDeleteEntitiesDialogIsOpen] =
    useState(false);
  const [entity, setEntity] = useState(emptyEntity);
  const [selectedEntities, setSelectedEntities] = useState(null);
  const [_, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  const crudService = useMemo(() => {
    return new BasicCrudService();
  }, []);

  const formik = useFormik({
    initialValues: entity,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object({
      year: Yup.number().required(),
      expected_surface: Yup.number().required(),
      expected_tonnage: Yup.number().required(),
      real_surface: Yup.number().required(),
      real_tonnage: Yup.number().required(),
      pi: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      values = clearEmptyFields(values);
      const isUpdate = Boolean(entity.id);

      try {
        const result = isUpdate
          ? await crudService.update("productor-farmer-stats", entity.id, values)
          : await crudService.save("productor-farmer-stats", values);

        if (isUpdate) {
          const index = findIndexById(entity.id);
          const _entities = entities;
          _entities[index] = result.data;
          setEntities(_entities);
        } else {
          entities.unshift(result.data);
        }

        formik.resetForm();

        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Donnée sauvegardée avec succès",
          life: 3000,
        });

        setEntityDialogIsOpen(false);
      } catch (error) {
        console.error(error);
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Le formulaire contient des erreurs",
          life: 3000,
        });
      }
    },
  });

  const getEntities = useCallback(
    async (onSuccess) => {
      const resourcePlurals = ["productor-farmer-stats", "pis"];

      try {
        const tasks = await Promise.all([
          ...resourcePlurals.map((resourcePlural) => {
            return crudService.get(`${resourcePlural}`);
          }),
        ]);
        onSuccess(tasks);
      } catch (error) {
        console.error("An error occured while fetching entities: ", error);
      }
    },
    [crudService]
  );

  function entitiesToSelectItems(entities, label = "name") {
    return entities.length !== 0
      ? entities.map((entity) => {
          return {
            label: entity.attributes[label],
            value: entity.id,
          };
        })
      : [];
  }

  useEffect(() => {
    getEntities((tasks) => {
      const [stats, pis] = tasks;
      setEntities(stats.data);
      setPis(pis.data);
    });
  }, [getEntities]);

  const openNew = () => {
    setEntity(emptyEntity);
    setSubmitted(false);
    setEntityDialogIsOpen(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setEntityDialogIsOpen(false);
    formik.resetForm();
  };

  const hideEntityCountryDialog = () => {
    setDeleteEntityDialogIsOpen(false);
  };

  const hideDeleteEntitiesDialog = () => {
    setDeleteEntitiesDialogIsOpen(false);
  };

  const editEntity = (entity) => {
    setEntity({ ...entity });
    setEntityDialogIsOpen(true);
  };

  const confirmDeleteCountry = (entity) => {
    setEntity(entity);
    setDeleteEntityDialogIsOpen(true);
  };

  async function deleteEntity() {
    try {
      await crudService.delete(resourcePlural, entity.id);
      const _entities = entities.filter((val) => val.id !== entity.id);
      setEntities(_entities);
      setDeleteEntityDialogIsOpen(false);
      setEntity(emptyEntity);
      toast.current.show({
        severity: "success",
        summary: "Succès",
        detail: "Donnée supprimée",
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erreur",
        detail: "Impossible de supprimer la donnée",
        life: 3000,
      });
      console.error(error);
    }
  }

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < entities.length; i++) {
      if (entities[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteEntitiesDialogIsOpen(true);
  };

  const deleteselectedCountrys = () => {
    let _entities = entities.filter((val) => !selectedEntities.includes(val));
    setEntities(_entities);
    setDeleteEntitiesDialogIsOpen(false);
    setSelectedEntities(null);
    toast.current.show({
      severity: "success",
      summary: "Succès",
      detail: " supprimés",
      life: 3000,
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Supprimer"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedEntities || !selectedEntities.length}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          label="Importer"
          chooseLabel="Importer"
          className="mr-2 inline-block"
        />
        <Button
          label="Exporter"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (entity) => {
    return (
      <EntityActions
        actions={[
          {
            icon: "pi pi-pencil",
            className: "p-button-rounded mr-2",
            onClick: () => {
              if (entity.attributes.pi && entity.attributes.pi.data) {
                entity.attributes.pi = entity.attributes.pi.data.id;
              }
              entity.attributes = {
                ...entity.attributes,
              };
              entity.attributes.id = entity.id;
              editEntity(entity.attributes);
            },
          },
          {
            icon: "pi pi-trash",
            className: "p-button-rounded p-button-danger mt-2",
            onClick: () => {
              confirmDeleteCountry(entity);
            },
          },
        ]}
      />
    );
  };

  const userDialogFooter = (
    <>
      <Button
        label="Annuler"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Valider"
        icon="pi pi-check"
        className="p-button-text"
        type="button"
        onClick={formik.handleSubmit}
      />
    </>
  );
  const deleteEntityDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideEntityCountryDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteEntity}
      />
    </>
  );
  const deleteEntitiesDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteEntitiesDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteselectedCountrys}
      />
    </>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />
          <DataTable
            ref={dt}
            value={entities}
            selection={selectedEntities}
            onSelectionChange={(e) => setSelectedEntities(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} éléments"
            globalFilter={globalFilter}
            emptyMessage="Aucune donnée trouvée"
            header={<Header setGlobalFilter={setGlobalFilter} title="Données agriculteurs / producteurs"/>}
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple" />
            <Column
              field="year"
              body={(data) => data.attributes.year}
              header="Année"
              sortable
            ></Column>
            <Column
              field="expected_surface"
              body={(data) => data.attributes.expected_surface}
              header="Superficie prévisionnelle de l’année"
              sortable
            ></Column>
            <Column
              field="expected_tonnage"
              body={(data) => data.attributes.expected_tonnage}
              header="Tonnage prévisionnel de l’année"
              sortable
            ></Column>
            <Column
              field="real_surface"
              body={(data) => data.attributes.real_surface}
              header="Totale de la superficie réalisée"
              sortable
            ></Column>
            <Column
              field="real_tonnage"
              body={(data) => data.attributes.real_tonnage}
              header="Tonnage réalisé de l'année"
              sortable
            ></Column>
            <Column
              field="pi"
              body={(data) => data.attributes.pi.data ? data.attributes.pi.data.attributes.label: ''}
              header="Plateforme d'innovation"
              sortable
            ></Column>
            
            <Column body={actionBodyTemplate} />
          </DataTable>
          <Dialog
            visible={entityDialogIsOpen}
            style={{ width: "700px" }}
            header={"Détails"}
            modal
            className="p-fluid"
            footer={userDialogFooter}
            onHide={hideDialog}
          >
            <div className="row">
              <DropdownField
                  label="Année"
                  name="year"
                  placeholder="Selectionner une année"
                  options={generateYears()}
                  formik={formik}
              />
              <DropdownField
                label="Plateforme d'innovation"
                name="pi"
                placeholder="Selectionner une plateforme d'innovation"
                options={entitiesToSelectItems(pis, "label")}
                formik={formik}
              />
            </div>
            <div className="row">
              <FormField
                label="Tonnage prévisionnel"
                name="expected_tonnage"
                formik={formik}
              />
              <FormField
                label="Tonnage réalisé"
                name="real_tonnage"
                formik={formik}
              />
            </div>
            <div className="row">
              <FormField
                label="Superficie prévisionnele"
                name="expected_surface"
                formik={formik}
              />
              <FormField
                label="Superficie réalisée"
                name="real_surface"
                formik={formik}
              />
            </div>
          </Dialog>
          <DeleteEntityDialog
            isOpen={deleteEntityDialogIsOpen}
            footer={deleteEntityDialogFooter}
            hide={hideEntityCountryDialog}
          />
          <DeleteEntitiesDialog
            isOpen={deleteEntitiesDialogIsOpen}
            footer={deleteEntitiesDialogFooter}
            hide={hideDeleteEntitiesDialog}
          />
        </div>
      </div>
    </div>
  );
}
