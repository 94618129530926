import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

export default function DropdownField({
  label,
  name,
  options,
  placeholder,
  formik,
}) {
  const error = formik.errors[name] && formik.touched[name];

  return (
    <div className="field">
      <label htmlFor="category">{label}</label>
      <Dropdown
        id={name}
        name={name}
        value={formik.values[name]}
        options={options}
        onChange={formik.handleChange}
        placeholder={placeholder}
        className={classNames({
          "p-invalid": error,
        })}
      />
      {error && <small className="p-error"> Ce champ est requis </small>}
    </div>
  );
}
