// eslint-disable-next-line no-use-before-define
export const ENPOINT = `/api`;
export const ACTORURL = `/actors`;
export const INTRANT_SUPPLIER_URL = `/intrant-supplier-stats`;
export const PI_URL = `/pis`;
export const EDUCATION_AND_RESEARCH_STAT = `/education-research-stats`;
export const GOVERNEMENT_STAT = `/government-stats`;
export const CIVIL_SOCIETY_STAT = `/civil-societies`;
export const POSTS_URL = `/posts`;
export const ASSURANCE_STAT = `/assurance-stats`;
export const MICRO_FIN_URL = `/microfinances`;
export const BASE_URL = `https://haidar.coraf.org`;
export const URL = `${BASE_URL}/api`;
