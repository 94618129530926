import BasicUrl from "./ApiConfig.service";

export default class BasicCrudService {
    get(resourcePlural, filter) {
        return BasicUrl.get(`${resourcePlural}?populate=*${filter ? '&' + filter : ""}`).then(
            ({ data }) => data
        );
    }

    save(resourcePlural, body, addDataSubKey = true) {
        const dataToSend = addDataSubKey ? { data: body } : body;
        return BasicUrl.post(`${resourcePlural}?populate=*`, dataToSend).then(
            ({ data }) => data
        );
    }

    delete(resourcePlural, id) {
        return BasicUrl.delete(`${resourcePlural}/${id}`).then(({ data }) => data);
    }

    update(resourcePlural, id, body, addDataSubKey = true) {
        const dataToSend = addDataSubKey ? { data: body } : body;
        return BasicUrl.put(`${resourcePlural}/${id}?populate=*`, dataToSend).then(
            ({ data }) => data
        );
    }
}