import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import BasicCrudService from "../../service/BasicCrudService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import IntrantCategory from "../../utils/IntrantCategory";

const IntrantsList = () => {
  const resourcePlural = "intrants";
  let emptyEntity = {
    label: "",
    category: "",
    average_price: "",
    unit_label: "",
  };

  const recipientSelectItems = Object.keys(IntrantCategory).map((key) => ({
    label: IntrantCategory[key],
    value: key,
  }));
  const [entities, setEntities] = useState(null);
  const [selectedIntrantCategory, setSelectedIntrantCategory] = useState(null);
  const [enityDialog, setEntityDialog] = useState(false);
  const [deleteEntityDialog, setDeleteEntityDialog] = useState(false);
  const [deleteEntitiesDialog, setDeleteEntitiesDialog] = useState(false);
  const [entity, setEntity] = useState(emptyEntity);
  const [selectedEntities, setSelectedEntities] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const crudService = new BasicCrudService();
  const requiredText = "Ce champ est obligatoire";

  const formik = useFormik({
    initialValues: entity,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object({
      label: Yup.string().required(),
      category: Yup.string().required(),
      average_price: Yup.number().required(),
      unit_label: Yup.string().required(),
    }),
    onSubmit: (values) => {
      saveEntity(values);
      formik.resetForm();
    },
  });

  useEffect(() => {
    crudService.get(`${resourcePlural}`).then((data) => {
      setEntities(data.data);
    });
  }, []);

  const openNew = () => {
    setEntity(emptyEntity);
    setEntityDialog(true);
  };

  const hideDialog = () => {
    setEntityDialog(false);
    formik.resetForm();
  };

  const hideDeleteEntityDialog = () => {
    setDeleteEntityDialog(false);
  };

  const hideDeletecountriesDialog = () => {
    setDeleteEntitiesDialog(false);
  };

  const saveEntity = (values) => {
    let _entities = [...entities];
    let _entity = { ...values };

    if (entity.id) {
      const index = findIndexById(entity.id);

      crudService
        .update(resourcePlural, entity.id, _entity)
        .then(({ data }) => {
          _entities[index] = data;
          setEntities(_entities);
          setEntityDialog(false);
          setEntity(emptyEntity);
          toast.current.show({
            severity: "success",
            summary: "Succès",
            detail: "Element mise à jour",
            life: 3000,
          });
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Erreur",
            detail: "Le formulaire contient des erreurs",
            life: 3000,
          });
        });
    } else {
      crudService
        .save(resourcePlural, _entity)
        .then((data) => {
          _entities.unshift(data.data);
          toast.current.show({
            severity: "success",
            summary: "Succès",
            detail: "Element enregistré",
            life: 3000,
          });
          setEntities(_entities);
          setEntityDialog(false);
          setEntity(emptyEntity);
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Erreur",
            detail: "Le formulaire contient des erreurs",
            life: 3000,
          });
        });
    }
  };

  const editEntitiy = (entitySelected) => {
    setEntity({ ...entitySelected.attributes, id: entitySelected.id });
    setEntityDialog(true);
  };

  const confirmDeleteEntitiy = (entity) => {
    setEntity(entity);
    setDeleteEntityDialog(true);
  };

  const deleteEntitiy = () => {
    crudService
      .delete(resourcePlural, entity.id)
      .then((data) => {
        let _entities = entities.filter((val) => val.id !== entity.id);
        setEntities(_entities);
        setDeleteEntityDialog(false);
        setEntity(emptyEntity);
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Element supprimée",
          life: 3000,
        });
      })
      .catch((err) => {
        setEntity(emptyEntity);
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Impossible de supprimer la Element",
          life: 3000,
        });
      });
  };

  const findIndexById = (id) => {
    return entities.findIndex((item) => item.id === id);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteEntitiesDialog(true);
  };

  const deleteselectedEntities = () => {
    let _entities = entities.filter((val) => !selectedEntities.includes(val));
    setEntities(_entities);
    setDeleteEntitiesDialog(false);
    setSelectedEntities(null);
    toast.current.show({
      severity: "success",
      summary: "Succès",
      detail: " supprimés",
      life: 3000,
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Supprimer"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedEntities || !selectedEntities.length}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exporter"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editEntitiy(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => confirmDeleteEntitiy(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Gestion des intrants</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const userDialogFooter = (
    <>
      <Button
        label="Annuler"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Valider"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => formik.submitForm()}
      />
    </>
  );
  const deleteEntitiyDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteEntityDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteEntitiy}
      />
    </>
  );
  const deleteEntitiesDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeletecountriesDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteselectedEntities}
      />
    </>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={entities}
            selection={selectedEntities}
            onSelectionChange={(e) => setSelectedEntities(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} éléments"
            globalFilter={globalFilter}
            emptyMessage="Aucune donnée trouvée"
            header={header}
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple"></Column>
            <Column
              field="label"
              body={(data) => data.attributes.label}
              header="Libellé"
              sortable
            ></Column>
            <Column
              field="category"
              body={(data) => {
                return IntrantCategory[data.attributes.category];
              }}
              header="Famille intrant"
              sortable
            ></Column>
            <Column
              field="unit_label"
              body={(data) => data.attributes.unit_label}
              header="Unité de mesure"
              sortable
            ></Column>
            <Column
              field="average_price"
              body={(data) => data.attributes.average_price}
              header="Prix moyen unitaire"
              sortable
            ></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>

          <Dialog
            visible={enityDialog}
            style={{ width: "450px" }}
            header="Détails Element"
            modal
            className="p-fluid"
            footer={userDialogFooter}
            onHide={hideDialog}
          >
            <div className="field">
              <label htmlFor="name">Libellé</label>
              <InputText
                id="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid": formik.errors.label && formik.touched.label,
                })}
              />
              {formik.errors.label && formik.touched.label && (
                <small className="p-error">{requiredText}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="category">Catégorie</label>
              <Dropdown
                value={formik.values.category}
                options={recipientSelectItems}
                className={classNames({
                  "p-invalid":
                    formik.errors.category && formik.touched.category,
                })}
                onChange={(e) => {
                  setSelectedIntrantCategory(e.value);
                  formik.setFieldValue("category", e.value);
                }}
                placeholder="Sélectionner une catégorie"
              />
              {formik.errors.category && formik.touched.category && (
                <small className="p-error">{requiredText}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="unit_label">Unité de mesure</label>
              <InputText
                id="unit_label"
                value={formik.values.unit_label}
                onChange={formik.handleChange}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid":
                    formik.errors.unit_label && formik.touched.unit_label,
                })}
              />
              {formik.errors.unit_label && formik.touched.unit_label && (
                <small className="p-error">{requiredText}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="unit_label">Prix moyen</label>
              <InputText
                id="average_price"
                value={formik.values.average_price}
                onChange={formik.handleChange}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid":
                    formik.errors.average_price && formik.touched.average_price,
                })}
              />
              {formik.errors.average_price && formik.touched.average_price && (
                <small className="p-error">{requiredText}</small>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteEntityDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteEntitiyDialogFooter}
            onHide={hideDeleteEntityDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entity && (
                <span>
                  Voulez vous vraiment supprimer <b>{entity.label}</b>?
                </span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteEntitiesDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteEntitiesDialogFooter}
            onHide={hideDeletecountriesDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entity && (
                <span>
                  Voulez vous vraiment supprimer les Elements sélectionnées?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default IntrantsList;
