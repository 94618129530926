import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import BasicCrudService from "../../service/BasicCrudService";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import {
  ACTORURL,
  ASSURANCE_STAT
} from "../../service/constant.service";


const AssuranceStatsList = () => {
  let emptyEntity = {
    year: "",
    subscribers_count: "",
    amount: "",
    compensation_amount: "",
  };

  const header = (
    <span className="ql-formats">
      <button className="ql-bold" aria-label="Bold"></button>
      <button className="ql-italic" aria-label="Italic"></button>
      <button className="ql-underline" aria-label="Underline"></button>
    </span>
  );

  const [entities, setEntities] = useState(null);
  const [enityDialog, setEntityDialog] = useState(false);
  const [deleteEntityDialog, setDeleteEntityDialog] = useState(false);
  const [deleteEntitiesDialog, setDeleteEntitiesDialog] = useState(false);
  const [entity, setEntity] = useState(emptyEntity);
  const [selectedEntities, setSelectedEntities] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isEdit, setIsEdit] = useState(true);
  const [actorList, setActorList] = useState([]);
  const toast = useRef(null);
  const dt = useRef(null);
  const crudService = new BasicCrudService();
  const requiredText = "Ce champ est obligatoire";

  const formik = useFormik({
    initialValues: {
      ...entity,
    },

    validateOnMount: true,
    enableReinitialize: true,

    onSubmit: (values) => {
      saveEntity(values);
      formik.resetForm();
    },
  });
  const flag = useRef(true);
  useEffect(() => {
    if (flag.current) {
      crudService
        .get(ASSURANCE_STAT)
        .then((data) => {
          setEntities(data?.data);
        })
        .catch((err) => {
        });
    }
    return () => (flag.current = false);
  }, []);

  useEffect(() => {
    if (flag.current) {
      crudService
        .get(ACTORURL)
        .then((data) => {
          setActorList(data?.data);
        })
        .catch((err) => {
        });
    }
    return () => (flag.current = false);
  }, []);

  const openNew = () => {
    setEntity(emptyEntity);
    setEntityDialog(true);
  };

  const hideDialog = () => {
    setIsEdit(true);
    setEntityDialog(false);
    formik.resetForm();
  };
  const handledEdit = () => {
    setIsEdit(!isEdit);
  };

  const hideDeleteEntityDialog = () => {
    setDeleteEntityDialog(false);
  };

  const hideDeletecountriesDialog = () => {
    setDeleteEntitiesDialog(false);
  };

  const saveEntity = () => {
    let _entities = [...entities];
    let _entity = {
      ...entity,
    };
    if (entity.id) {
      delete _entity.updatedAt;
      delete _entity.createdAt;
      const index = findIndexById(entity.id);
      crudService
        .update(ASSURANCE_STAT, _entity.id, {
          ..._entity,
        })
        .then(({ data }) => {
          _entities[index] = data;
          setEntities(_entities);
          setEntityDialog(false);
          setIsEdit(true);
          setEntity(emptyEntity);
          toast.current.show({
            severity: "success",
            summary: "Succès",
            detail: "Element mise à jour",
            life: 3000,
          });
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Erreur",
            detail: "Le formulaire contient des erreurs",
            life: 3000,
          });
        });
    } else {
      crudService
        .save(ASSURANCE_STAT, {
          ..._entity,
        })
        .then((data) => {
          _entities.unshift(data?.data);
          toast.current.show({
            severity: "success",
            summary: "Succès",
            detail: "Element enregistré",
            life: 3000,
          });
          setIsEdit(true);
          setEntities(_entities);
          setEntityDialog(false);
          setEntity(emptyEntity);
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Erreur",
            detail: "Le formulaire contient des erreurs",
            life: 3000,
          });
        });
    }
  };

  const editEntitiy = (entitySelected) => {
    setEntity({
      ...entitySelected.attributes,
      id: entitySelected.id,
      actor: entitySelected?.attributes?.actor?.data?.id,
    });

    setEntityDialog(true);
  };

  const confirmDeleteEntitiy = (entity) => {
    setEntity(entity);
    setDeleteEntityDialog(true);
  };

  const deleteEntitiy = () => {
    crudService
      .delete(ASSURANCE_STAT, entity.id)
      .then((data) => {
        let _entities = entities.filter((val) => val.id !== entity.id);
        setEntities(_entities);
        setDeleteEntityDialog(false);
        setEntity(emptyEntity);
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Element ",
          life: 3000,
        });
      })
      .catch((err) => {
        setEntity(emptyEntity);
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Impossible de supprimer la Element",
          life: 3000,
        });
      });
  };

  const findIndexById = (id) => {
    return entities.findIndex((item) => item.id === id);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteEntitiesDialog(true);
  };

  const deleteselectedEntities = () => {
    let _entities = entities.filter((val) => !selectedEntities.includes(val));
    setEntities(_entities);
    setDeleteEntitiesDialog(false);
    setSelectedEntities(null);
    toast.current.show({
      severity: "success",
      summary: "Succès",
      detail: " supprimés",
      life: 3000,
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={() => (openNew(), setIsEdit(false))}
          />
          <Button
            label="Supprimer"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedEntities || !selectedEntities.length}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exporter"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editEntitiy(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => confirmDeleteEntitiy(rowData)}
        />
      </div>
    );
  };

  const ContentHeader = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Statistique des assurances</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const userDialogFooter = (
    <>
      <Button
        label="Annuler"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      {isEdit && (
        <Button
          label="Editer"
          icon="pi pi-check"
          className="p-button-text"
          onClick={handledEdit}
        />
      )}
      {!isEdit && (
        <Button
          label="Valider"
          icon="pi pi-check"
          className="p-button-text"
          onClick={saveEntity}
        />
      )}
    </>
  );
  const deleteEntitiyDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteEntityDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteEntitiy}
      />
    </>
  );
  const deleteEntitiesDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeletecountriesDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteselectedEntities}
      />
    </>
  );

  const onInputChange = (e) => {
    let value;
    value = (e.target && e.target.value) || "";
    let _entity = { ...entity };
    _entity[`${e.target.id}`] = value;
    setEntity(_entity);
  };

  const selectActorList = actorList?.map((item) => ({
    label: item.attributes.org_name,
    value: item.id,
  }));


  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={entities}
            selection={selectedEntities}
            onSelectionChange={(e) => setSelectedEntities(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} éléments"
            globalFilter={globalFilter}
            emptyMessage="Aucune donnée trouvée"
            header={ContentHeader}
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple"></Column>
            <Column
              field="year"
              body={(data) => data?.attributes?.year}
              header="Année"
              sortable
            ></Column>
            <Column
              field="subscribers_count"
              body={(data) => data?.attributes?.subscribers_count}
              header="Nombre de souscriptions à l'assurance"
              sortable
            ></Column>
            <Column
              field="amount"
              body={(data) => data?.attributes?.amount}
              header="Montant des souscriptions à l'assurance"
              sortable
            ></Column>
            <Column
              field="compensation_amount"
              body={(data) => data?.attributes?.compensation_amount}
              header="Montant de dédommagement obtenus de l'assurance"
            ></Column>
            <Column
              field="actor"
              body={(data) =>
                data?.attributes?.actor?.data?.attributes?.org_name
              }
              header="Acteur"
            ></Column>

            <Column
              field="createdAt"
              header="Date de création"
              sortable
              body={(data) => data?.attributes?.createdAt}
            ></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>

          <Dialog
            visible={enityDialog}
            style={{ width: "450px" }}
            header="Détails Element"
            modal
            className="p-fluid"
            footer={userDialogFooter}
            onHide={hideDialog}
          >
            <div className="field">
              <label htmlFor="title">
                Année <span className="required">*</span>
              </label>
              <InputText
                disabled={isEdit}
                id="year"
                value={formik.values.year}
                onChange={onInputChange}
                required
                type="number"
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid": formik.errors.year && formik.touched.year,
                })}
              />
              {formik.errors.year && formik.touched.year && (
                <small className="p-invalid">{formik.errors.year}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="content">
                Nombre de souscriptions à l'assurance{" "}
                <span className="required">*</span>
                </label>
                <InputText
                    disabled={isEdit}
                    type="number"
                    id="subscribers_count"
                    value={formik.values.subscribers_count}
                    required
                    name="subscribers_count"
                    onChange={onInputChange}
                    rows={3}
                    cols={20}
                    className={classNames({
                        "p-invalid":
                            formik.errors.subscribers_count &&
                            formik.touched.subscribers_count,
                    })}
                />
                {formik.errors.subscribers_count &&
                    formik.touched.subscribers_count && (
                        <small className="p-invalid">
                            {formik.errors.subscribers_count}
                        </small>
                    )}
            </div>
            <div className="field">
              <label htmlFor="public">
                Montant des souscriptions à l'assurance{" "}
                <span className="required">*</span>
                </label>
                <InputText
                    disabled={isEdit}
                    type="number"
                    id="amount"
                    value={formik.values.amount}
                    required
                    name="amount"
                    onChange={onInputChange}
                    rows={3}
                    cols={20}
                    className={classNames({
                        "p-invalid":
                            formik.errors.amount && formik.touched.amount,
                    })}
                />
                {formik.errors.amount && formik.touched.amount && (
                    <small className="p-invalid">{formik.errors.amount}</small>
                )}
            </div>
            <div className="field">
                <label htmlFor="public">
                    Montant de dédommagement obtenus de l'assurance{" "}
                    <span className="required">*</span>
                </label>
                <InputText
                    disabled={isEdit}
                    type="number"
                    id="compensation_amount"
                    value={formik.values.compensation_amount}
                    required
                    name="compensation_amount"
                    onChange={onInputChange}
                    rows={3}
                    cols={20}
                    className={classNames({
                        "p-invalid":
                            formik.errors.compensation_amount &&
                            formik.touched.compensation_amount,
                    })}
                />
                {formik.errors.compensation_amount &&
                    formik.touched.compensation_amount && (
                        <small className="p-invalid">
                            {formik.errors.compensation_amount}
                        </small>
                    )}
            </div>
            <div className="field">
              <label htmlFor="public">
                Acteur <span className="required">*</span>
              </label>
              <Dropdown
                disabled={isEdit}
                id="actor"
                name="actor"
                value={formik.values.actor}
                options={selectActorList}
                onChange={onInputChange}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid": formik.errors.actor && formik.touched.actor,
                })}
              />
            </div>
          </Dialog>

          <Dialog
            visible={deleteEntityDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteEntitiyDialogFooter}
            onHide={hideDeleteEntityDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entity && (
                <span>Voulez vous vraiment supprimer cet element </span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteEntitiesDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteEntitiesDialogFooter}
            onHide={hideDeletecountriesDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entity && (
                <span>
                  Voulez vous vraiment supprimer les elements sélectionnées?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default AssuranceStatsList;
