import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import App from './App';
import { Error } from './pages/Error';
import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';
import AuthContext from './context/authContext';

const AppWrapper = () => {
    let location = useLocation();
    const [token , setToken] = useState(localStorage.getItem('token'));
    const [isAuthenticated, setIsAuthenticated] = useState(token !== null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <AuthContext.Provider value = {{
            isAuthenticated,
            token,
            login: token => {
                setIsAuthenticated(true);
                setToken(token);
                localStorage.setItem('token' , token);
            },
            logout: () => {
                setIsAuthenticated(false);
                setToken(null);
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
            }
        }}>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/error" element={<Error />} />
                <Route path="/notfound" element={<NotFound />} />
                <Route path="*" element={<App />} />
            </Routes>
        </AuthContext.Provider>
    );
};

export default AppWrapper;
