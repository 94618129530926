import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import BasicCrudService from "../../service/BasicCrudService";
import { Link } from "react-router-dom";

const CommentsList = () => {
  const resourcePlural = "comments";

  const [entity, setEntity] = useState(null);
  const [entities, setEntities] = useState(null);
  const [deleteEntityDialog, setDeleteEntityDialog] = useState(false);
  const [deleteEntitiesDialog, setDeleteEntitiesDialog] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const crudService = new BasicCrudService();

  useEffect(() => {
    crudService.get(`${resourcePlural}`).then((data) => {
      setEntities(data.data);
    });
  }, []);

  const hideDeleteEntityDialog = () => {
    setDeleteEntityDialog(false);
  };

  const hideDeletecountriesDialog = () => {
    setDeleteEntitiesDialog(false);
  };

  const confirmDeleteEntitiy = (entity) => {
    setEntity(entity);
    setDeleteEntityDialog(true);
  };

  const confirmSwitchEntityState = (entity) => {
    let _entities = [...entities];
    const index = findIndexById(entity.id);
    crudService
        .update(resourcePlural, entity.id, {
          validated: !entity.attributes.validated
        })
        .then(({ data }) => {
          _entities[index] = data;
          setEntities(_entities);
          toast.current.show({
            severity: "success",
            summary: "Succès",
            detail: "Element mise à jour",
            life: 3000,
          });
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Erreur",
            detail: "Une erreur est survenue",
            life: 3000,
          });
        });
  }

  const deleteEntitiy = () => {
    crudService
      .delete(resourcePlural, entity.id)
      .then((data) => {
        let _entities = entities.filter((val) => val.id !== entity.id);
        setEntities(_entities);
        setDeleteEntityDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Element supprimé",
          life: 3000,
        });
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Impossible de supprimer la Element",
          life: 3000,
        });
      });
  };

  const findIndexById = (id) => {
    return entities.findIndex((item) => item.id === id);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteEntitiesDialog(true);
  };

  const deleteselectedEntities = () => {
    let _entities = entities.filter((val) => !selectedEntities.includes(val));
    setEntities(_entities);
    setDeleteEntitiesDialog(false);
    setSelectedEntities(null);
    toast.current.show({
      severity: "success",
      summary: "Succès",
      detail: " supprimés",
      life: 3000,
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="Supprimer"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedEntities || !selectedEntities.length}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exporter"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => confirmDeleteEntitiy(rowData)}
        />
        <Button
          icon={'pi ' + (rowData.attributes.validated ? 'pi-ban': 'pi-check')}
          className={"p-button-rounded mt-2 " + (!rowData.attributes.validated ? 'p-button-success': 'p-button-warning')}
          onClick={() => confirmSwitchEntityState(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Gestion des commentaires</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const deleteEntitiyDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteEntityDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteEntitiy}
      />
    </>
  );
  const deleteEntitiesDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeletecountriesDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteselectedEntities}
      />
    </>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={entities}
            selection={selectedEntities}
            onSelectionChange={(e) => setSelectedEntities(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} éléments"
            globalFilter={globalFilter}
            emptyMessage="Aucune donnée trouvée"
            header={header}
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple"></Column>
            <Column
              field="content"
              body={(data) => data.attributes.content}
              header="Contenu"
              sortable
            ></Column>
            <Column
              field="owner"
              body={(data) => data.attributes.owner.data.attributes.username}
              header="Auteur du poste"
              sortable
            ></Column>
            <Column
              field="created_at"
              body={(data) => data.attributes.createdAt}
              header="Date"
              sortable
            ></Column>
            <Column
              field="validated"
              body={(data) => data.attributes.validated ? 'Validé': 'En attente de validation'}
              header="Etat du commentaire"
              sortable
            ></Column>
            <Column
              field="post"
              body={(data) => <Link to={'/posts/' + data.id}>{data.attributes.post.data.attributes.title}</Link>}
              header="Poste"
              sortable
            ></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>

          <Dialog
            visible={deleteEntityDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteEntitiyDialogFooter}
            onHide={hideDeleteEntityDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entity && (
                <span>
                  Voulez vous vraiment supprimer ce commentaire?
                </span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteEntitiesDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteEntitiesDialogFooter}
            onHide={hideDeletecountriesDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entity && (
                <span>
                  Voulez vous vraiment supprimer les Elements sélectionnées?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default CommentsList;
