import React, { useEffect, useRef, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { Button, classNames, FileUpload, Toast } from "primereact";

import * as Yup from "yup";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import BasicCrudService from "../../service/BasicCrudService";
import { BASE_URL, GOVERNEMENT_STAT } from "../../service/constant.service";
import { useFormik } from "formik";
import axiosInstance from "../../service/ApiConfig.service";

const GovernementStatsList = () => {
  const [instrantSupplierStat, setInstrantSupplierStat] = React.useState(null);
  const [dialog, setDialog] = useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [image, setImage] = useState(null);
  const [selectedEntities, setSelectedEntities] = React.useState(null);
  const [actors, setActors] = React.useState([]);
  const [deleteManyDialog, setDeleteManyDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [entitie, setEntitie] = React.useState({
    year: "",
    goal: "",
    description: "",
    budget: "",
    participents_count: "",
    actor: ""
  });
  const toast = useRef(null);

  const dt = useRef(null);

  const flag = useRef(true);
  const displayDialog = () => {
    setEntitie(emptyEntity);
    setDialog(true);
  };
  const hideDialog = () => {
    setDialog(false);
    setIsEdit(false);
  };

  useEffect(() => {
    if (flag.current) {
      (async () => fetchData())();
      (async () => fetchActors())();
    }
  }, []);

  const deleteEntitiy = () => {
    const crudService = new BasicCrudService();
    crudService
      .delete(GOVERNEMENT_STAT, entitie?.id)
      .then((res) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Les données ont été supprimées",
          life: 3000,
        });
        fetchData();
        setDeleteDialog(false);
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Une erreur s'est produite",
          life: 3000,
        });
        setDeleteDialog(false);
      });
  };

  const deleteSelectedEntities = () => {
    let _entities = instrantSupplierStat.filter(
        (val) => !selectedEntities.includes(val.id)
      ),
      _selectedEntities = null;
    setInstrantSupplierStat(_entities);
    setDeleteManyDialog(false);
    setSelectedEntities(_selectedEntities);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Les données ont été supprimées",
      life: 3000,
    });
  };

  const deleteEntitiyDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteDialog(false)}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteEntitiy}
      />
    </>
  );
  const deleteEntitiesDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteManyDialog(false)}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedEntities}
      />
    </>
  );

  const fetchActors = () => {
    const crudService = new BasicCrudService();
    crudService
      .get("/actors", "filters[recipientType][category]=GOVERNMENT")
      .then((data) => {
        setActors(data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchData = () => {
    const crudService = new BasicCrudService();
    crudService
      .get(GOVERNEMENT_STAT)
      .then((data) => {
        setInstrantSupplierStat(data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let emptyEntity = {
    year: "",
    goal: "",
    description: "",
    budget: "",
    participents_count: "",
    innovation_description: "",
    actor: ""
  };

  const formik = useFormik({
    initialValues: entitie,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object({
      year: Yup.number("Entrée invalide").required("Ce champ est obligatoire"),
      participents_count: Yup.number("Entrée invalide").required(
        "Ce champ est obligatoire"
      ),
      budget: Yup.number("Entrée invalide").required(
        "Ce champ est obligatoire"
      ),
      goal: Yup.string().required(),
      description: Yup.string().required(),
    }),
    onSubmit: (values) => {
      if (image !== null) {
        const formData = new FormData();
        formData.append('files', image);
        
        axiosInstance.post('/upload', formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(({ data: createdFile }) => {
          persistEntity(values, createdFile)
          setImage(null);
        })
        .catch(e => setImage(null));
      }
      else {
        persistEntity(values);
      }
    },
  });

  const onInputChange = (e, name) => {
    const val = e.target.value;
    formik.setFieldValue(name, val);
  };

  const actorsToBeSelected =
    actors.length !== 0
      ? actors.map((entity) => {
          return {
            label: entity.attributes?.org_name,
            value: entity.id,
          };
        })
      : [];

  const editEntitiy = (entitySelected) => {
    setEntitie({
      ...entitySelected.attributes,
      id: entitySelected.id,
      actor: entitySelected.attributes?.actor?.data?.id,
      report: entitySelected.attributes?.report?.data?.id,
    });
    setDialog(true);
    setIsEdit(true);
  };
  const confirmDeleteEntitiy = (entity) => {
    setEntitie(entity);
    setDeleteDialog(true);
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exporter"
          icon="pi pi-upload"
          className="p-button-help"
        />
      </React.Fragment>
    );
  };
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={displayDialog}
          />
          <Button
            label="Supprimer"
            icon="pi pi-trash"
            className="p-button-danger"
            disabled={!selectedEntities || !selectedEntities.length}
            onClick={() => setDeleteManyDialog(true)}
          />
        </div>
      </React.Fragment>
    );
  };
  const dialogFooter = (
    <>
      <Button
        label="Annuler"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label={isEdit ? "Editer" : "Valider"}
        icon={isEdit ? "pi pi-pencil" : "pi pi-check"}
        className="p-button-text"
        onClick={() => formik.submitForm()}
      />
    </>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editEntitiy(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => confirmDeleteEntitiy(rowData)}
        />
      </div>
    );
  };

  const persistEntity = (values, file = null) => {
    const crudService = new BasicCrudService();
    const dataToSend = !file ? {...values} : {...values, report: file[0].id};

    if (isEdit) {
      crudService
      .update(GOVERNEMENT_STAT, values?.id, dataToSend)
      .then((res) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Les données ont été mises à jour",
          life: 3000,
        });
        fetchData();
        setDialog(false);
        setIsEdit(false);
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Une erreur s'est produite",
          life: 3000,
        });
      });
    }
    else {
      crudService
          .save(GOVERNEMENT_STAT, dataToSend)
          .then((data) => {
            fetchData();
            hideDialog();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Statistiques fournisseur intrant enregistré",
              life: 3000,
            });
            setEntitie(emptyEntity);
            formik.resetForm();
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            dataKey="id"
            paginator
            ref={dt}
            onSelectionChange={(e) => setSelectedEntities(e.value)}
            selection={selectedEntities}
            rows={10}
            value={instrantSupplierStat}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} éléments"
            emptyMessage="Aucune donnée trouvée"
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple"></Column>

            <Column
              field="year"
              header="Année"
              body={(data) => data?.attributes?.year}
              sortable
            ></Column>
            <Column
              field="actor"
              header="Acteur"
              body={(data) => data?.attributes?.actor?.data?.attributes?.identity}
              sortable
            ></Column>
            <Column
              field="goal"
              header="Objet de l’activité"
              body={(data) =>
                data?.attributes?.goal
              }
              sortable
            ></Column>
            <Column
              field="description"
              header="Contenu descriptif de l’activité"
              body={(data) =>
                data?.attributes?.description
              }
              sortable
            ></Column>
            <Column
              field="budget"
              header="Budget"
              body={(data) => data?.attributes?.budget}
              sortable
            ></Column>
            <Column
              field="participents_count"
              header="Nombre de participants"
              body={(data) => data?.attributes?.participents_count}
              sortable
            ></Column>
            <Column
              field="report"
              body={
                (data) => <a rel="noreferrer" target="_blank" href={BASE_URL + data?.attributes?.report?.data?.attributes?.url}>
                  {data?.attributes?.report?.data?.attributes?.caption || data?.attributes?.report?.data?.attributes?.name}
                </a>
              }
              header="Pièce jointe"
              sortable
            ></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>
          <Dialog
            visible={dialog}
            style={{ width: "450px" }}
            header="Détails"
            modal
            className="p-fluid"
            footer={dialogFooter}
            onHide={hideDialog}
          >
            <div className="field">
              <label htmlFor="year">Année</label>
              <InputText
                id="year"
                name="year"
                value={formik.values.year}
                onChange={(e) => onInputChange(e, "year")}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid": formik.errors.year && formik.touched.year,
                })}
              />
              {formik.errors.year && formik.touched.year && (
                <small className="p-error">{formik.errors.year}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="actor">Acteur</label>
              <Dropdown
                value={formik.values.actor}
                options={actorsToBeSelected}
                onChange={(e) => onInputChange(e, "actor")}
                placeholder="Sélectionner l'acteur"
                className={classNames({
                  "p-invalid":
                    formik.errors.actor && formik.touched.actor,
                })}
              />
              {formik.errors.actor && formik.touched.actor && (
                <small className="p-error">{formik.errors.actor}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="goal">Objectif</label>
              <InputText
                id="goal"
                value={formik.values.goal}
                onChange={(e) => onInputChange(e, "goal")}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid":
                    formik.errors.goal &&
                    formik.touched.goal,
                })}
              />
              {formik.errors.goal &&
                formik.touched.goal && (
                  <small className="p-error">
                    {formik.errors.goal}
                  </small>
                )}
            </div>
            <div className="field">
              <label htmlFor="description">Contenu descriptif</label>
              <InputText
                id="description"
                value={formik.values.description}
                onChange={(e) => onInputChange(e, "description")}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid":
                    formik.errors.description &&
                    formik.touched.description,
                })}
              />
              {formik.errors.description &&
                formik.touched.description && (
                  <small className="p-error">
                    {formik.errors.description}
                  </small>
                )}
            </div>
            <div className="field">
              <label htmlFor="budget">Budget</label>
              <InputText
                id="budget"
                value={formik.values.budget}
                onChange={(e) => onInputChange(e, "budget")}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid":
                    formik.errors.budget &&
                    formik.touched.budget,
                })}
              />
              {formik.errors.budget &&
                formik.touched.budget && (
                  <small className="p-error">
                    {formik.errors.budget}
                  </small>
                )}
            </div>
            <div className="field">
              <label htmlFor="participents_count">Nombre de participants</label>
              <InputText
                id="participents_count"
                value={formik.values.participents_count}
                onChange={(e) => onInputChange(e, "participents_count")}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid":
                    formik.errors.participents_count && formik.touched.participents_count,
                })}
              />
              {formik.errors.participents_count && formik.touched.participents_count && (
                <small className="p-error">{formik.errors.participents_count}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="report">Pièce jointe</label>
              <FileUpload
                name="upload"
                customUpload={true}
                mode="advanced"
                auto={true}
                chooseLabel="Fichier"
                uploadHandler={(e) => {
                  setImage(e.files[0]);
                }
              }
            />
            </div>
          </Dialog>
          <Dialog
            visible={deleteDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteEntitiyDialogFooter}
            onHide={() => setDeleteDialog(false)}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entitie && (
                <span>
                  Voulez vous vraiment supprimer <b>{entitie?.label}</b>?
                </span>
              )}
            </div>
          </Dialog>
          <Dialog
            visible={deleteManyDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteEntitiesDialogFooter}
            onHide={() => setDeleteManyDialog(false)}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entitie && (
                <span>
                  Voulez vous vraiment supprimer les Elements sélectionnées?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default GovernementStatsList;
