export const breadcrumb = [
  { path: "/", parent: "Tableau de bord", label: "Tableau de bord" },
  { path: "/users", parent: "", label: "Gestion des utilisateurs" },
  { path: "/countries", parent: "", label: "Gestion des pays" },
  { path: "/users", parent: "", label: "Gestion des utilisateurs" },
  { path: "/pis", parent: "", label: "Gestion des PIs" },
  { path: "/age-ranges", parent: "", label: "Gestion des tranches d'age" },
  { path: "/products", parent: "", label: "Gestion des produits" },
  { path: "/actors", parent: "", label: "Listes des actors" },
  { path: "/comments", parent: "", label: "Gestion des commentaires" },
  { path: "/intrants", parent: "", label: "Gestion des intrants" },
  { path: "/micro-finances", parent: "", label: "Gestion des micro-finances" },
  { path: "/civil-society", parent: "", label: "Gestion des données société civile/" },
  {
    path: "/productors-farmers",
    parent: "",
    label: "Données producteurs / agriculteurs",
  },
  {
    path: "/governments",
    parent: "",
    label: "Données governement",
  },
  {
    path: "/education-research-stats",
    parent: "",
    label: "Données éducation / recherche",
  },
  {
    path: "/recipient-types",
    parent: "",
    label: "Gestion des types de bénéficiaire",
  },
  {
    path: "/pi_zones",
    parent: "",
    label: "Gestion des zones de couverture",
  },
  {
    path: "/assurance-stats",
    parent: "",
    label: "Statistiques assurance",
  }
];

export const menu = [
  {
    label: "Page d'accueil",
    icon: "pi pi-fw pi-home",
    items: [{ label: "Tableau de bord", icon: "pi pi-fw pi-home", to: "/" }],
  },
  {
    label: "Administration",
    icon: "pi pi-fw pi-home",
    items: [
      {
        label: "Gestion des utilisateurs",
        icon: "pi pi-fw pi-users",
        to: "/users",
      },
      {
        label: "Gestion des pays",
        icon: "pi pi-fw pi-map-marker",
        to: "/countries",
      },
      { label: "Gestion des PI", icon: "pi pi-fw pi-table", to: "/pis" },
      {
        label: "Gestion des tranches d'age",
        icon: "pi pi-fw pi-sort-numeric-down",
        to: "/age-ranges",
      },
      {
        label: "Gestion des produits",
        icon: "pi pi-fw pi-box",
        to: "/products",
      },
      {
        label: "Types de bénéficiaire",
        icon: "pi pi-fw pi-user",
        to: "/recipient-types",
      },
      {
        label: "Listes des acteurs",
        icon: "pi pi-fw pi-users",
        to: "/actors",
      },
     
      {
        label: "Gestion des posts",
        icon: "pi pi-fw pi-folder",
        to: "/posts",
      },
      {
        label: "Gestion des zones de couverture",
        icon: "pi pi-fw pi-map-marker",
        to: "/pi_zones",
      },
      {
        label: "Gestion des commentaires",
        icon: "pi pi-fw pi-comments",
        to: "/comments",
      },
      {
        label: "Gestion des intrants",
        icon: "pi pi-fw pi-sun",
        to: "/intrants",
      },
    ],
  },
  {
    label: "Données et statisques",
    icon: "pi pi-fw pi-home",
    items: [
      {
        label: "Données producteurs/agriculteurs",
        icon: "pi pi-fw pi-database",
        to: "/productors-farmers/",
      },
      {
        label: "Statistiques des fournisseurs d'intrants",
        icon: "pi pi-fw pi-database",
        to: "/intrant-suppliers-stats",
      },

      {
        label: "Statistiques education et recherche",
        icon: "pi pi-fw pi-database",
        to: "/education-research-stats",
      },
      {
        label: "Statistiques des micro-finances",
        icon: "pi pi-fw pi-database",
        to: "/micro-finances",
      },
      {
        label: "Statistiques assurance",
        icon: "pi pi-fw pi-database",
        to: "/assurance-stats",
      },
      {
        label: "Statistiques gouvernement",
        icon: "pi pi-fw pi-database",
        to: "/governments",
      },
      {
        label: "Statistiques société civile et média",
        icon: "pi pi-fw pi-database",
        to: "/civil-society",
      },
    ],
  },
];
