import { Dialog } from "primereact/dialog";

export default function DeleteEntityDialog({ isOpen, footer, hide }) {
  return (
    <Dialog
      visible={isOpen}
      style={{ width: "450px" }}
      header="Confirm"
      modal
      footer={footer}
      onHide={hide}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        <span>Voulez vous vraiment supprimer cet élément?</span>
      </div>
    </Dialog>
  );
}
