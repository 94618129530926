import { Dialog } from "primereact/dialog";

export default function DeleteEntitiesDialog({ isOpen, footer, hide }) {
  return (
    <Dialog
      visible={isOpen}
      style={{ width: "450px" }}
      header="Confirm"
      footer={footer}
      onHide={hide}
      modal
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        <span>Voulez vous vraiment supprimer les éléments sélectionnées ?</span>
      </div>
    </Dialog>
  );
}
