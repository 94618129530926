import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import BasicCrudService from "../../service/BasicCrudService";
import { getResources, entitiesToSelectItems } from "../../lib";
import { ACTORURL } from "../../service/constant.service";
import { utils } from "../../utils";

const ActorsList = () => {
  let resourcePlural = "actors";
  let emptyActor = {
    actor_type: "",
    identity: "",
    address: "",
    org_name: "",
    phone: "",
    email: "",
    code: "",
    gender: "",
    ageRange: null,
    recipientType: null,
    coraf_agent: false,
    consultant: false,
  };

  const [actors, setActors] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [userDialog, setUserDialog] = useState(false);
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [deleteusersDialog, setDeleteusersDialog] = useState(false);
  const [pis, setPiZones] = useState([]);
  const [actor, setActor] = useState(emptyActor);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [recipientData, setRecipientData] = useState([]);
  const [ageRanges, setAgeRanges] = useState([]);
  const toast = useRef(null);
  const dt = useRef(null);
  const crudService = new BasicCrudService();
  const flag = useRef(true);
  const [isRecipient, setIsRecipient] = useState(false);

  const actorType = [
    { label: "Bénéficiaire", value: "RECIPIENT" },
    { label: "Acteur externe", value: "EXTERNAL" },
  ];

  useEffect(() => {
    getResources(["pis"], (tasks) => {
      const [pis] = tasks;
      setPiZones(pis.data);
    });
  }, []);

  useEffect(() => {
    if (flag.current) {
      crudService
        .get(ACTORURL)
        .then((data) => {
          setActors(data.data);
        })
        .catch((err) => {
        });
    }
    return () => (flag.current = false);
  }, []);
  useEffect(() => {
    if (
      isRecipient === true &&
      recipientData.length === 0 &&
      ageRanges.length === 0
    ) {
      crudService
        .get("recipient-types")
        .then((data) => {
          const _data = data.data;
          const _recipientData = [];
          _data.map((recip) => {
            _recipientData.push({
              label: recip.attributes.name,
              value: recip.id,
            });
          });
          setRecipientData(_recipientData);
        })
        .catch((err) => {
        });

      crudService
        .get("age-ranges")
        .then((data) => {
          const _data = data.data;
          const _ageRanges = [];
          _data.map((age) => {
            _ageRanges.push({
              label: `${age.attributes.min}-${age.attributes.max}`,
              value: age.id,
            });
          });
          setAgeRanges(_ageRanges);
        })
        .catch((err) => {
        });
    }
  }, [isRecipient]);

  const openNew = () => {
    setActor(emptyActor);
    setSubmitted(false);
    setUserDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setUserDialog(false);
  };
  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };

  const hideDeleteusersDialog = () => {
    setDeleteusersDialog(false);
  };

  const saveActor = () => {
    setSubmitted(true);
    let _actors = [...actors];
    let _actor = { ...actor };

    if (!_actor.pi || _actor.pi.data !== undefined) {
      delete _actor.pi;
    }
    if (!_actor.ageRange || _actor.ageRange.data !== undefined) {
      delete _actor.ageRange;
    }
    if (!_actor.recipientType || _actor.recipientType.data !== undefined) {
      delete _actor.recipientType;
    }

    if (actor.id) {
      const index = findIndexById(actor.id);
      crudService
        .update(ACTORURL, actor.id, _actor)
        .then((data) => {
          _actors[index] = data.data;
          setActors(_actors);
          setUserDialog(false);
          setActor(emptyActor);
          setEditMode(false);
          toast.current.show({
            severity: "success",
            summary: "Succès",
            detail: "utilisateur mis à jour",
            life: 3000,
          });
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Erreur",
            detail: "Le formulaire contient des erreurs",
            life: 3000,
          });
        });
    } else {
      const refactActor = utils.RemoveDataEmpty(_actor);
      crudService
        .save(ACTORURL, refactActor)
        .then((data) => {
          _actors.unshift(data.data);
          toast.current.show({
            severity: "success",
            summary: "Succès",
            detail: "utilisateur enregistré",
            life: 3000,
          });
          setActors(_actors);
          setUserDialog(false);
          setActor(emptyActor);
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Erreur",
            detail: "Le formulaire contient des erreurs",
            life: 3000,
          });
        });
    }
  };

  const editUser = (actor) => {
    setEditMode(true);

    if (actor.attributes.pi?.data) {
      actor.attributes.pi = actor.attributes.pi?.data?.id;
    }
    if (actor.attributes.ageRange?.data) {
      actor.attributes.ageRange = actor.attributes.ageRange?.data?.id;
    }
    if (actor.attributes.recipientType?.data) {
      actor.attributes.recipientType = actor.attributes.recipientType?.data?.id;
    }

    setIsRecipient(actor.attributes.actor_type === "RECIPIENT");
    setActor({ ...actor?.attributes, id: actor?.id });
    setUserDialog(true);
  };

  const confirmDeleteUser = (actor) => {
    setActor(actor);
    setDeleteUserDialog(true);
  };

  const deleteUser = () => {
    crudService
      .delete(resourcePlural, actor.id)
      .then((data) => {
        let _actors = actors?.filter((val) => val.id !== actor.id);
        setActors(_actors);
        setDeleteUserDialog(false);
        setActor(emptyActor);
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Utilisateur supprimé",
          life: 3000,
        });
      })
      .catch((err) => {
        setActor(emptyActor);
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Impossible de supprimer l'urilisateur",
          life: 3000,
        });
      });
  };

  const findIndexById = (id) => {
    return actors?.findIndex((item) => item.id === id);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteusersDialog(true);
  };

  const deleteselectedUsers = () => {
    let _actors = actors?.filter((val) => !selectedUsers.includes(val));
    setActors(_actors);
    setDeleteusersDialog(false);
    setSelectedUsers(null);
    toast.current.show({
      severity: "success",
      summary: "Succès",
      detail: "utilisateurs supprimés",
      life: 3000,
    });
  };

  const onInputChange = (e) => {
    let value;
    value = (e.target && e.target.value) || "";
    let _actor = { ...actor };
    _actor[`${e.target.id}`] = value;
    setActor(_actor);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Supprimer"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedUsers || !selectedUsers.length}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exporter"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editUser(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => confirmDeleteUser(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Gestion des acteurs</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const userDialogFooter = (
    <>
      <Button
        label="Annuler"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Valider"
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveActor}
      />
    </>
  );
  const deleteUserDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteUserDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteUser}
      />
    </>
  );
  const deleteUsersDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteusersDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteselectedUsers}
      />
    </>
  );

  const handledDrop = (e) => {
    let status = e.target.value;
    setActor({ ...actor, actor_type: status });
    if (status === "RECIPIENT") setIsRecipient(true);
    else setIsRecipient(false);
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={actors}
            selection={selectedUsers}
            // onSelectionChange={(e) => setSelectedUsers(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} éléments"
            globalFilter={globalFilter}
            emptyMessage="Aucun utilisateur trouvé"
            header={header}
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple"></Column>
            <Column
              filter
              filterPlaceholder="Identité"
              body={(data) => data?.attributes?.identity}
              field="identity"
              header="Identité"
              sortable
            ></Column>
            <Column
              field="orgName"
              header="Nom organisation"
              sortable
              body={(data) => data?.attributes?.org_name}
            ></Column>
            <Column
              field="phone"
              header="Téléphone"
              sortable
              body={(data) => data?.attributes?.phone}
            ></Column>
            <Column
              field="email"
              header="Adresse Email"
              sortable
              body={(data) => data?.attributes?.email}
            ></Column>
            <Column
              field="code"
              header="Code"
              sortable
              body={(data) => data?.attributes?.code}
            ></Column>
            <Column
              field="gender"
              header="genre"
              sortable
              body={(data) => data?.attributes?.gender}
            ></Column>
            <Column
              field="corafAgence"
              header="Agence coraf"
              sortable
              body={(data) =>
                utils.booleanChecker(data?.attributes?.coraf_agent)
              }
            ></Column>
            <Column
              field="consultant"
              header="Consultant"
              sortable
              body={(data) =>
                utils.booleanChecker(data?.attributes?.consultant)
              }
            ></Column>
            <Column
              field="actor_type"
              header="Type d'acteur"
              sortable
              body={(data) => data?.attributes?.actor_type}
            ></Column>
            <Column
              field="ageRange"
              header="Tranche d'age"
              sortable
              body={(data) =>
                data?.attributes?.ageRange?.data
                  ? data?.attributes?.ageRange?.data?.attributes?.max +
                    "-" +
                    data?.attributes?.ageRange?.data?.attributes?.min
                  : "-"
              }
            ></Column>
            <Column
              field="createdAt"
              header="Date de création"
              sortable
              body={(data) => data?.attributes?.createdAt}
            ></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>

          <Dialog
            visible={userDialog}
            style={{ width: "450px" }}
            header="Détails Acteur"
            modal
            className="p-fluid"
            footer={userDialogFooter}
            onHide={hideDialog}
          >
            <div className="field">
              <label htmlFor="identity">Identifiant</label>
              <InputText
                id="identity"
                value={actor.identity}
                onChange={onInputChange}
                required
                className={classNames({
                  "p-invalid": submitted && !actor.identity,
                })}
              />
              {submitted && !actor.identity && (
                <small className="p-invalid">Ce champ est obligatoire.</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="org_name">Nom de l'organisation</label>
              <InputText
                id="org_name"
                value={actor?.org_name}
                onChange={onInputChange}
              />
            </div>
            <div className="field">
              <label htmlFor="phone">Téléphone</label>
              <InputText
                id="phone"
                value={actor?.phone}
                onChange={onInputChange}
              />
            </div>
            <div className="field">
              <label htmlFor="email">Email</label>
              <InputText
                id="email"
                value={actor?.email}
                onChange={onInputChange}
                required
                className={classNames({
                  "p-invalid": submitted && !actor?.email,
                })}
              />
              {submitted && !actor?.email && !editMode && (
                <small className="p-invalid">Ce champ est obligatoire.</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="address">Adresse</label>
              <InputText
                id="address"
                value={actor?.address}
                onChange={onInputChange}
                rows={3}
                cols={20}
              />
            </div>

            <div className="field">
              <label htmlFor="code">Code</label>
              <InputText
                id="code"
                value={actor?.code}
                onChange={onInputChange}
                rows={3}
                cols={20}
              />
            </div>
            <div className="field">
              <label htmlFor="gender">Genre</label>
              <InputText
                id="gender"
                value={actor?.gender}
                onChange={onInputChange}
                rows={3}
                cols={20}
              />
            </div>

            <div className="field mr-2">
              <div className="field-checkbox">
                <Checkbox
                  inputId="binary"
                  checked={actor.consultant}
                  onChange={(e) =>
                    setActor({ ...actor, consultant: e.checked })
                  }
                />
                <label htmlFor="binary">Consultant</label>
              </div>
              <div className="field-checkbox">
                <Checkbox
                  inputId="corafAgent"
                  checked={actor.coraf_agent}
                  onChange={(e) =>
                    setActor({ ...actor, coraf_agent: e.checked })
                  }
                />
                <label htmlFor="corafAgent">Agence coraf</label>
              </div>
            </div>

            <Dropdown
              value={actor.actor_type}
              options={actorType}
              onChange={handledDrop}
              placeholder="Sélectionner le type d'acteur"
            />
            <div style={{ margin: "1em 0" }}></div>
            <Dropdown
              label="Plateforme d'innovation"
              id="pi"
              placeholder="Selectionner une plateforme d'innovation"
              onChange={(e) => {
                setActor({ ...actor, pi: e.target.value });
              }}
              value={actor.pi}
              options={entitiesToSelectItems(pis, "label")}
            />
            {isRecipient && (
              <div className="grid grid-nogutter justify-content-between mt-4">
                <Dropdown
                  value={actor.recipientType}
                  options={recipientData}
                  onChange={(e) =>
                    setActor({ ...actor, recipientType: e.target.value })
                  }
                  placeholder="Type de bénéficiaire"
                  id="recipicient"
                  className="col-6 w-5"
                />

                <Dropdown
                  value={actor.ageRange}
                  options={ageRanges}
                  onChange={(e) =>
                    setActor({ ...actor, ageRange: e.target.value })
                  }
                  placeholder="Tranche d'âge"
                  id="age"
                  className="col-6 w-5"
                />
              </div>
            )}
          </Dialog>

          <Dialog
            visible={deleteUserDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteUserDialogFooter}
            onHide={hideDeleteUserDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {actor && (
                <span>
                  Voulez vous vraiment supprimer <b>{actor.username}</b>?
                </span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteusersDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteUsersDialogFooter}
            onHide={hideDeleteusersDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {actor && (
                <span>
                  Voulez vous vraiment supprimer les utilisateurs sélectionnés?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default ActorsList;
