import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";
import DropdownField from "../../components/FormFields/DropdownField/DropdownField";
import FormField from "../../components/FormFields/FormField/FormField";
import * as Yup from "yup";
import { clearEmptyFields } from "../../utils";
import BasicCrudService from "../../service/BasicCrudService";
import EntityActions from "../../components/EntityActions/EntityActions";
import { BASE_URL } from "../../service/constant.service";
import { Image } from "primereact/image";
import axiosInstance from "../../service/ApiConfig.service";

const emptyEntity = {
  code: "",
  short_label: "",
  label: "",
  idea: "",
  open_date: "",
  planned_end_date: "",
  closing_date: "",
  country: "",
  product: "",
  recipients: [],
  external_actors: [],
};
const PIsList = () => {
  const resourcePlural = "pis";

  const [entities, setEntities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [products, setProducts] = useState([]);
  const [entityDialogIsOpen, setEntityDialogIsOpen] = useState(false);
  const [deleteEntityDialogIsOpen, setDeleteEntityDialogIsOpen] =
    useState(false);
  const [deleteEntitiesDialogIsOpen, setDeleteEntitiesDialogIsOpen] =
    useState(false);
  const [entity, setEntity] = useState(emptyEntity);
  const [selectedCountrys, setSelectedCountries] = useState(null);
  const [_submitted, setSubmitted] = useState(false);
  const [image, setImage] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  const crudService = useMemo(() => {
    return new BasicCrudService();
  }, []);

  const formik = useFormik({
    initialValues: entity,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object({
      // PI Fields
      code: Yup.string().required(),
      short_label: Yup.string().required(),
      label: Yup.string().required(),
      idea: Yup.string().required(),

      open_date: Yup.string().required(),
      planned_end_date: Yup.string().required(),
      closing_date: Yup.string().required(),

      country: Yup.string().required(),
      product: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      values = clearEmptyFields(values);

      try {
        const platform = {
          code: values.code,
          short_label: values.short_label,
          label: values.label,
          idea: values.idea,
          open_date: values.open_date,
          planned_end_date: values.planned_end_date,
          closing_date: values.closing_date,
          country: values.country,
          product: values.product,
          recipients: values.recipients,
          external_actors: values.external_actors,
        };

        if (image !== null) {
          const formData = new FormData();
          formData.append('files', image);
          
          axiosInstance.post('/upload', formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
          .then(async ({ data: createdFile }) => {
            await persistEntity(platform, createdFile)
            setImage(null);
          })
          .catch(e => setImage(null));
        }
        else {
          await persistEntity(platform);
        }
      } catch (error) {
        console.error(error);
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Le formulaire contient des erreurs",
          life: 3000,
        });
      }
    },
  });

  const persistEntity = async (platform, file = null) => {
    const isUpdate = Boolean(entity.id);
    const dataToSend = !file ? {...platform} : {...platform, cover: file[0].id};

    const platformResult = isUpdate
      ? await crudService.update("pis", entity.id, dataToSend)
      : await crudService.save("pis", dataToSend);

    if (isUpdate) {
      const index = findIndexById(entity.id);
      const _entities = entities;
      _entities[index] = platformResult.data;
      setEntities(_entities);
    } else {
      entities.unshift(platformResult.data);
    }
    formik.resetForm();

    toast.current.show({
      severity: "success",
      summary: "Succès",
      detail: "PI sauvegardée avec succès",
      life: 3000,
    });

    setEntityDialogIsOpen(false);
  }

  const getEntities = useCallback(
    async (onSuccess) => {
      const resourcePlurals = ["pis", "countries", "products"];

      try {
        const tasks = await Promise.all([
          ...resourcePlurals.map((resourcePlural) => {
            let filter = "";
            if (resourcePlural === "products") {
              filter = "&filters[parent][id][$null]=true";
            }
            return crudService.get(`${resourcePlural}`, filter);
          }),
        ]);
        onSuccess(tasks);
      } catch (error) {
        console.error("An error occured while fetching entities: ", error);
      }
    },
    [crudService]
  );

  function entitiesToSelectItems(entities, label = "name") {
    return entities.length !== 0
      ? entities.map((entity) => {
          return {
            label: entity.attributes[label],
            value: entity.id,
          };
        })
      : [];
  }

  useEffect(() => {
    getEntities((tasks) => {
      const [pis, countries, products] = tasks;
      setEntities(pis.data);
      setCountries(countries.data);
      setProducts(products.data);
    });
  }, [getEntities]);

  const openNew = () => {
    setEntity(emptyEntity);
    setSubmitted(false);
    setEntityDialogIsOpen(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setEntityDialogIsOpen(false);
    formik.resetForm();
  };

  const hideDeleteCountryDialog = () => {
    setDeleteEntityDialogIsOpen(false);
  };

  const hideDeletecountriesDialog = () => {
    setDeleteEntitiesDialogIsOpen(false);
  };

  const editEntity = (entity) => {
    setEntity({ ...entity });
    setEntityDialogIsOpen(true);
  };

  const confirmDeleteCountry = (entity) => {
    setEntity(entity);
    setDeleteEntityDialogIsOpen(true);
  };

  const deleteCountry = () => {
    crudService
      .delete(resourcePlural, entity.id)
      .then((data) => {
        let _entities = entities.filter((val) => val.id !== entity.id);
        setEntities(_entities);
        setDeleteEntityDialogIsOpen(false);
        setEntity(emptyEntity);
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "PI supprimée",
          life: 3000,
        });
      })
      .catch((err) => {
        setEntity(emptyEntity);
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Impossible de supprimer la PI",
          life: 3000,
        });
      });
  };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < entities.length; i++) {
      if (entities[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteEntitiesDialogIsOpen(true);
  };

  const deleteselectedCountrys = () => {
    let _entities = entities.filter((val) => !selectedCountrys.includes(val));
    setEntities(_entities);
    setDeleteEntitiesDialogIsOpen(false);
    setSelectedCountries(null);
    toast.current.show({
      severity: "success",
      summary: "Succès",
      detail: " supprimés",
      life: 3000,
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Supprimer"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedCountrys || !selectedCountrys.length}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          label="Importer"
          chooseLabel="Importer"
          className="mr-2 inline-block"
        />
        <Button
          label="Exporter"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (entity) => {
    return (
      <EntityActions
        actions={[
          {
            icon: "pi pi-pencil",
            className: "p-button-rounded mr-2",
            onClick: () => {
              if (entity.attributes.country && entity.attributes.country.data) {
                entity.attributes.country = entity.attributes.country.data.id;
              }
              if (entity.attributes.product && entity.attributes.product.data) {
                entity.attributes.product = entity.attributes.product.data.id;
              }
              if (
                entity.attributes.recipients &&
                entity.attributes.recipients.data
              ) {
                entity.attributes.recipients =
                  entity.attributes.recipients.data.map(
                    (recipient) => recipient.id
                  );
              }
              if (
                entity.attributes.external_actors &&
                entity.attributes.external_actors.data
              ) {
                entity.attributes.external_actors =
                  entity.attributes.external_actors.data.map(
                    (actor) => actor.id
                  );
              }
              entity.attributes = {
                ...entity.attributes,
              };
              entity.attributes.id = entity.id;
              editEntity(entity.attributes);
            },
          },
          {
            icon: "pi pi-trash",
            className: "p-button-rounded p-button-danger mt-2",
            onClick: () => {
              confirmDeleteCountry(entity);
            },
          },
        ]}
      />
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0"> Gestion des PI </h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const userDialogFooter = (
    <>
      <Button
        label="Annuler"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Valider"
        icon="pi pi-check"
        className="p-button-text"
        type="button"
        onClick={formik.handleSubmit}
      />
    </>
  );
  const deleteCountryDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteCountryDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteCountry}
      />
    </>
  );
  const deleteCountrysDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeletecountriesDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteselectedCountrys}
      />
    </>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={entities}
            selection={selectedCountrys}
            onSelectionChange={(e) => setSelectedCountries(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} éléments"
            globalFilter={globalFilter}
            emptyMessage="Aucune PI trouvée"
            header={header}
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple"> </Column>
            <Column
              field="code"
              body={(data) => data.attributes.code}
              header="Code"
              sortable
            ></Column>
            <Column
              field="code"
              body={(data) => <img src={BASE_URL + data.attributes?.cover?.data?.attributes?.formats?.thumbnail?.url} alt=""/> }
              header="Image de couverture"
              sortable
            ></Column>
            <Column
              field="short_label"
              body={(data) => data.attributes.short_label}
              header="Libellé court"
              sortable
            ></Column>
            <Column
              field="label"
              body={(data) => data.attributes.label}
              header="Libellé"
              sortable
            ></Column>
            <Column
              field="idea"
              body={(data) => data.attributes.idea}
              header="Idée"
              sortable
            ></Column>
            <Column
              field="open_date"
              body={(data) => data.attributes.open_date}
              header="Date d'ouverture"
              sortable
            ></Column>
            <Column
              field="open_date"
              body={(data) => data.attributes.open_date}
              header="Date de fermeture"
              sortable
            ></Column>
            <Column
              field="planned_end_date"
              body={(data) => data.attributes.planned_end_date}
              header="Date de fin prévue"
              sortable
            ></Column>
            <Column body={actionBodyTemplate}> </Column>
          </DataTable>
          <Dialog
            visible={entityDialogIsOpen}
            style={{ width: "700px" }}
            header={"Détails de la Plateforme d'Innovation (PI)"}
            modal
            className="p-fluid"
            footer={userDialogFooter}
            onHide={hideDialog}
          >
            <div className="row">
              <FormField label="Code" name="code" formik={formik} />
              <FormField
                label="Libellé court"
                name="short_label"
                formik={formik}
              />
            </div>
            <FormField label="Libellé" name="label" formik={formik} />
            <FormField label="Idée" name="idea" formik={formik} />
            <Divider> </Divider> <p> Dates </p>
            <div className="row">
              <div className="field">
                <label htmlFor="label"> Ouverture </label>
                <Calendar
                  id="open_date"
                  value={new Date(formik.values.open_date)}
                  className={classNames({
                    "p-invalid":
                      formik.errors.open_date && formik.touched.open_date,
                  })}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="field">
                <label htmlFor="planned_end_date"> Fermeture prévue </label>
                <Calendar
                  id="planned_end_date"
                  value={new Date(formik.values.planned_end_date)}
                  className={classNames({
                    "p-invalid":
                      formik.errors.planned_end_date &&
                      formik.touched.planned_end_date,
                  })}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="field">
                <label htmlFor="closing_date"> Fermeture </label>
                <Calendar
                  id="closing_date"
                  value={new Date(formik.values.closing_date)}
                  className={classNames({
                    "p-invalid":
                      formik.errors.closing_date && formik.touched.closing_date,
                  })}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <Divider> </Divider>
            <div className="row">
              <DropdownField
                label="Pays"
                name="country"
                placeholder="Selectionner un pays"
                options={entitiesToSelectItems(countries)}
                formik={formik}
              />
              <DropdownField
                label="Produit de la plateforme"
                name="product"
                placeholder="Selectionner un produit"
                options={entitiesToSelectItems(products)}
                formik={formik}
              />
            </div>
            <div className="field">
                <label htmlFor="cover"> Image de couverture </label>
                {formik?.values?.image?.data?.attributes?.url && (
                  <Image
                    src={`${BASE_URL}${formik?.values?.image?.data?.attributes?.url}`}
                    zoomSrc={`${BASE_URL}${formik?.values?.image?.data?.attributes?.url}`}
                    alt="Image"
                    width="80"
                    height="60"
                    preview
                  />
                )}
                <FileUpload
                  name="upload"
                  customUpload={true}
                  mode="advanced"
                  auto={true}
                  chooseLabel="Fichier"
                  accept="image/*"
                  uploadHandler={(e) => {
                    setImage(e.files[0]);
                  }}
                />
              </div>
          </Dialog>
          <Dialog
            visible={deleteEntityDialogIsOpen}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteCountryDialogFooter}
            onHide={hideDeleteCountryDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entity && (
                <span>
                  Voulez vous vraiment supprimer <b> {entity.username} </b>?
                </span>
              )}
            </div>
          </Dialog>
          <Dialog
            visible={deleteEntitiesDialogIsOpen}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteCountrysDialogFooter}
            onHide={hideDeletecountriesDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entity && (
                <span>
                  Voulez vous vraiment supprimer les PI sélectionnées ?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default PIsList;
