import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import BasicCrudService from "../../service/BasicCrudService";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import RecipientCategory from "../../utils/RecipientCategory";
import { BASE_URL, PI_URL, POSTS_URL } from "../../service/constant.service";
import { FileUpload } from "primereact/fileupload";
import { utils } from "../../utils";
import { MultiSelect } from "primereact/multiselect";
import { Editor } from "primereact/editor";
import axiosInstance from "../../service/ApiConfig.service";
import { Checkbox } from "primereact/checkbox";

const PostsList = () => {
  let emptyEntity = {
    title: "",
    content: "",
    categories: "",
    is_public: false,
    published: false,
    image: null,
  };
  const publicType = [
    { label: "Oui", value: true },
    { label: "Non", value: false },
  ];

  const header = (
    <span className="ql-formats">
      <button className="ql-bold" aria-label="Bold"></button>
      <button className="ql-italic" aria-label="Italic"></button>
      <button className="ql-underline" aria-label="Underline"></button>
    </span>
  );

  const is_public = {
    oui: "oui",
    non: "non",
  };
  const [shared, setShared] = useState(false);
  const [entities, setEntities] = useState(null);
  const [image, setImage] = useState(null);
  const [enityDialog, setEntityDialog] = useState(false);
  const [deleteEntityDialog, setDeleteEntityDialog] = useState(false);
  const [deleteEntitiesDialog, setDeleteEntitiesDialog] = useState(false);
  const [entity, setEntity] = useState(emptyEntity);
  const [selectedEntities, setSelectedEntities] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isEdit, setIsEdit] = useState(true);
  const [textareaInput, setTextareaInput] = useState("");
  const [piList, setPiList] = useState([]);
  const toast = useRef(null);
  const dt = useRef(null);
  const crudService = new BasicCrudService();
  const requiredText = "Ce champ est obligatoire";

  const recipientSelectItems = Object.keys(RecipientCategory).map((key) => ({
    label: RecipientCategory[key],
    value: key,
  }));

  const formik = useFormik({
    initialValues: {
      ...entity,
      categories: entity.categories
        ? entity.categories.map((item) => item?.value)
        : [],
    },

    validateOnMount: true,
    enableReinitialize: true,

    onSubmit: (values) => {
      saveEntity(values);
      formik.resetForm();
    },
  });
  const flag = useRef(true);
  useEffect(() => {
    if (flag.current) {
      crudService
        .get(POSTS_URL)
        .then((data) => {
          setEntities(data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => (flag.current = false);
  }, []);

  useEffect(() => {
    if (flag.current) {
      crudService
        .get(PI_URL)
        .then((data) => {
          setPiList(data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => (flag.current = false);
  }, []);

  const openNew = () => {
    setEntity(emptyEntity);
    setEntityDialog(true);
  };

  const hideDialog = () => {
    setIsEdit(true);
    setEntityDialog(false);
    formik.resetForm();
  };
  const handledEdit = () => {
    setIsEdit(!isEdit);
  };

  const hideDeleteEntityDialog = () => {
    setDeleteEntityDialog(false);
  };

  const hideDeletecountriesDialog = () => {
    setDeleteEntitiesDialog(false);
  };

  const saveEntity = () => {
    let _entities = [...entities];
    let _entity = {
      ...entity,
      pis: shared ? entity.pis : [],
    };
    _entity.content = textareaInput;

    if (entity.id) {
      delete _entity.updatedAt;
      delete _entity.createdAt;
      delete _entity.image;
      delete _entity.pi;
      const index = findIndexById(entity.id);

      if (image !== null) {
        const formData = new FormData();
        formData.append("files", image);
        formData.append("source", "content-manager");

        axiosInstance
          .post("/upload", formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then(({ data: createdImage }) => {
            setImage(null);
            crudService
              .update(POSTS_URL, _entity.id, {
                ..._entity,
                image: createdImage[0].id,
                publisher: localStorage.getItem("userId"),
              })
              .then(({ data }) => {
                console.log(data);
                _entities[index] = data;
                setEntities(_entities);
                setEntityDialog(false);
                setIsEdit(true);
                setEntity(emptyEntity);
                toast.current.show({
                  severity: "success",
                  summary: "Succès",
                  detail: "Element mise à jour",
                  life: 3000,
                });
              })
              .catch((err) => {
                toast.current.show({
                  severity: "error",
                  summary: "Erreur",
                  detail: "Le formulaire contient des erreurs",
                  life: 3000,
                });
              });
          })
          .catch((e) => setImage(null));
      } else {
        crudService
          .update(POSTS_URL, _entity.id, {
            ..._entity,
            publisher: localStorage.getItem("userId"),
          })
          .then(({ data }) => {
            _entities[index] = data;
            setEntities(_entities);
            setEntityDialog(false);
            setIsEdit(true);
            setEntity(emptyEntity);
            toast.current.show({
              severity: "success",
              summary: "Succès",
              detail: "Element mise à jour",
              life: 3000,
            });
          })
          .catch((err) => {
            toast.current.show({
              severity: "error",
              summary: "Erreur",
              detail: "Le formulaire contient des erreurs",
              life: 3000,
            });
          });
      }
    } else {
      const payload = utils.RemoveDataEmpty(_entity);
      // console.log(payload);
      if (image !== null) {
        const formData = new FormData();
        formData.append("files", image);
        formData.append("source", "content-manager");

        axiosInstance
          .post("/upload", formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then(({ data: createdImage }) => {
            setImage(null);
            crudService
              .save(POSTS_URL, {
                ...payload,
                image: createdImage[0].id,
                publisher: localStorage.getItem("userId"),
              })
              .then((data) => {
                _entities.unshift(data?.data);
                toast.current.show({
                  severity: "success",
                  summary: "Succès",
                  detail: "Element enregistré",
                  life: 3000,
                });
                setIsEdit(true);
                setEntities(_entities);
                setEntityDialog(false);
                setEntity(emptyEntity);
              })
              .catch((err) => {
                toast.current.show({
                  severity: "error",
                  summary: "Erreur",
                  detail: "Le formulaire contient des erreurs",
                  life: 3000,
                });
              });
          })
          .catch((err) => {
            setImage(null);
          });
      } else {
        crudService
          .save(POSTS_URL, {
            ...payload,
            publisher: localStorage.getItem("userId"),
          })
          .then((data) => {
            setImage(null);
            _entities.unshift(data?.data);
            toast.current.show({
              severity: "success",
              summary: "Succès",
              detail: "Element enregistré",
              life: 3000,
            });
            setIsEdit(true);
            setEntities(_entities);
            setEntityDialog(false);
            setEntity(emptyEntity);
          })
          .catch((err) => {
            setImage(null);
            toast.current.show({
              severity: "error",
              summary: "Erreur",
              detail: "Le formulaire contient des erreurs",
              life: 3000,
            });
          });
      }
    }
  };

  const editEntitiy = (entitySelected) => {
    setEntity({
      ...entitySelected.attributes,
      id: entitySelected.id,
      pi: entitySelected.attributes.pi.data.id,
      pis: entitySelected.attributes.pis?.data?.map((item) => item.id),
    });
    console.log(entitySelected);
    entitySelected?.attributes?.pis?.data?.length > 0
      ? setShared(true)
      : setShared(false);
    setEntityDialog(true);
  };

  const confirmDeleteEntitiy = (entity) => {
    setEntity(entity);
    setDeleteEntityDialog(true);
  };

  const deleteEntitiy = () => {
    crudService
      .delete(POSTS_URL, entity.id)
      .then((data) => {
        let _entities = entities.filter((val) => val.id !== entity.id);
        setEntities(_entities);
        setDeleteEntityDialog(false);
        setEntity(emptyEntity);
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Element ",
          life: 3000,
        });
      })
      .catch((err) => {
        setEntity(emptyEntity);
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Impossible de supprimer la Element",
          life: 3000,
        });
      });
  };

  const findIndexById = (id) => {
    return entities.findIndex((item) => item.id === id);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteEntitiesDialog(true);
  };

  const deleteselectedEntities = () => {
    let _entities = entities.filter((val) => !selectedEntities.includes(val));
    setEntities(_entities);
    setDeleteEntitiesDialog(false);
    setSelectedEntities(null);
    toast.current.show({
      severity: "success",
      summary: "Succès",
      detail: " supprimés",
      life: 3000,
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={() => (openNew(), setIsEdit(false))}
          />
          <Button
            label="Supprimer"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedEntities || !selectedEntities.length}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exporter"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editEntitiy(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => confirmDeleteEntitiy(rowData)}
        />
      </div>
    );
  };

  const ContentHeader = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Gestion des posts</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const userDialogFooter = (
    <>
      <Button
        label="Annuler"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      {isEdit && (
        <Button
          label="Editer"
          icon="pi pi-check"
          className="p-button-text"
          onClick={handledEdit}
        />
      )}
      {!isEdit && (
        <Button
          label="Valider"
          icon="pi pi-check"
          className="p-button-text"
          onClick={saveEntity}
        />
      )}
    </>
  );
  const deleteEntitiyDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteEntityDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteEntitiy}
      />
    </>
  );
  const deleteEntitiesDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeletecountriesDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteselectedEntities}
      />
    </>
  );

  const onInputChange = (e) => {
    let value;
    value = (e.target && e.target.value) || "";
    let _entity = { ...entity };
    _entity[`${e.target.id}`] = value;
    setEntity(_entity);
  };
  const convertHtmlToText = (html) => {
    let strippedHtml = html?.replace(/<[^>]+>/g, "");
    return strippedHtml;
  };

  const selectPilist = piList.map((item) => ({
    label: item.attributes.label,
    value: item.id,
  }));

  // pi list without piselected
  const selectPilistWithoutSelected = piList
    .filter((item) => item.id !== entity.pi)
    .map((item) => ({
      label: item.attributes.label,
      value: item.id,
    }));

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={entities}
            selection={selectedEntities}
            onSelectionChange={(e) => setSelectedEntities(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} éléments"
            globalFilter={globalFilter}
            emptyMessage="Aucune donnée trouvée"
            header={ContentHeader}
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple"></Column>
            <Column
              field="title"
              body={(data) => data?.attributes?.title}
              header="Titre"
              sortable
            ></Column>
            <Column
              field="publisher"
              body={(data) =>
                data?.attributes?.publisher?.data.attributes.username
              }
              header="Auteur"
              sortable
            ></Column>
            <Column
              field="category"
              // body={(data) => data?.attributes?.categories}
              header="Catégorie"
              sortable
            ></Column>
            <Column
              field="is_public"
              body={(data) => utils.booleanChecker(data.attributes.is_public)}
              header="Statut public"
              sortable
            ></Column>
            <Column
              field="published"
              body={(data) => utils.booleanChecker(data.attributes.published)}
              header="Commentaires autorisés?"
              sortable
            ></Column>

            <Column
              field="createdAt"
              header="Date de création"
              sortable
              body={(data) => data?.attributes?.createdAt}
            ></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>

          <Dialog
            visible={enityDialog}
            style={{ width: "450px" }}
            header="Détails Element"
            modal
            className="p-fluid"
            footer={userDialogFooter}
            onHide={hideDialog}
          >
            <div className="field">
              <label htmlFor="title">Titre</label>
              <InputText
                disabled={isEdit}
                id="title"
                value={formik.values.title}
                onChange={onInputChange}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid": formik.errors.title && formik.touched.title,
                })}
              />
              {formik.errors.title && formik.touched.title && (
                <small className="p-error">{requiredText}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="content">Contenu</label>
              {isEdit && (
                <InputText
                  disabled={isEdit}
                  id="content"
                  value={convertHtmlToText(formik.values.content)}
                  required
                  rows={3}
                  cols={20}
                  className={classNames({
                    "p-invalid":
                      formik.errors.content && formik.touched.content,
                  })}
                />
              )}
              {!isEdit && (
                <Editor
                  id="content"
                  disabled={isEdit}
                  style={{ height: "320px" }}
                  onTextChange={(e) => setTextareaInput(e.htmlValue)}
                  value={formik.values.content}
                />
              )}
              {formik.errors.content && formik.touched.content && (
                <small className="p-error">{requiredText}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="public">Public</label>
              <Dropdown
                disabled={isEdit}
                value={formik.values.is_public}
                options={publicType}
                id="is_public"
                className={classNames({
                  "p-invalid":
                    formik.errors.is_public && formik.touched.is_public,
                })}
                onChange={(e) =>
                  setEntity({ ...entity, is_public: e.target.value })
                }
                placeholder="Sélectionner une catégorie"
              />
              {formik.errors.is_public && formik.touched.is_public && (
                <small className="p-error">{requiredText}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="public">Autoriser les commentaires?</label>
              <Dropdown
                disabled={isEdit}
                value={formik.values.published}
                options={publicType}
                id="published"
                className={classNames({
                  "p-invalid":
                    formik.errors.published && formik.touched.published,
                })}
                onChange={(e) =>
                  setEntity({ ...entity, published: e.target.value })
                }
                placeholder="Sélectionner une catégorie"
              />
              {formik.errors.published && formik.touched.published && (
                <small className="p-error">{requiredText}</small>
              )}
            </div>

            <div className="field">
              <label htmlFor="public">Categorie</label>

              <MultiSelect
                disabled={isEdit}
                value={formik.values.categories}
                options={recipientSelectItems}
                id="categories"
                className={classNames({
                  "p-invalid":
                    formik.errors.categories && formik.touched.categories,
                })}
                onChange={(e) => {
                  setEntity({
                    ...entity,
                    categories:
                      e.target.value?.length > 0
                        ? e.target.value.map((item) => {
                            return {
                              value: item,
                              label: recipientSelectItems.find(
                                (i) => i.value === item
                              )?.label,
                            };
                          })
                        : [],
                  });
                }}
                placeholder="Sélectionner une catégorie"
              />
              {formik.errors.categories && formik.touched.categories && (
                <small className="p-error">{requiredText}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="public">PI de référence</label>
              <Dropdown
                disabled={isEdit}
                value={formik.values.pi}
                options={selectPilist}
                id="pi"
                className={classNames({
                  "p-invalid": formik.errors.pi && formik.touched.pi,
                })}
                onChange={(e) => setEntity({ ...entity, pi: e.target.value })}
                placeholder="Sélectionner une catégorie"
              />
              {formik.errors.pi && formik.touched.pi && (
                <small className="p-error">{requiredText}</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="public">Partagé dans d'autres PI?</label>
              <div className="p-field-checkbox">
                <Checkbox
                  inputId="oui"
                  value={true}
                  onChange={(e) => setShared(true)}
                  checked={shared}
                  disabled={isEdit}
                />
                <label
                  htmlFor="oui"
                  style={{ marginRight: "15px" }}
                  className="p-checkbox-label"
                >
                  Oui
                </label>
                <Checkbox
                  inputId="non"
                  value={false}
                  disabled={isEdit}
                  onChange={(e) => setShared(false)}
                  checked={!shared}
                />

                <label htmlFor="non" className="p-checkbox-label">
                  Non
                </label>
              </div>
            </div>

            {shared && (
              <div className="field">
                <label htmlFor="public">PI de partage</label>
                <MultiSelect
                  disabled={isEdit}
                  value={formik.values.pis}
                  options={selectPilistWithoutSelected}
                  id="shared_pi"
                  className={classNames({
                    "p-invalid": formik.errors.pis && formik.touched.pis,
                  })}
                  onChange={(e) =>
                    setEntity({
                      ...entity,
                      pis: e.target.value,
                    })
                  }
                  placeholder="Sélectionner les PI de partage"
                />
                {formik.errors.pis && formik.touched.pis && (
                  <small className="p-error">{requiredText}</small>
                )}
              </div>
            )}

            {formik?.values?.image?.data?.attributes?.url && (
              <Image
                src={`${BASE_URL}${formik?.values?.image?.data?.attributes?.url}`}
                zoomSrc={`${BASE_URL}${formik?.values?.image?.data?.attributes?.url}`}
                alt="Image"
                width="80"
                height="60"
                preview
              />
            )}
            <FileUpload
              disabled={isEdit}
              name="upload"
              customUpload={true}
              mode="advanced"
              auto={true}
              chooseLabel="Fichier"
              accept="image/*"
              uploadHandler={(e) => {
                setImage(e.files[0]);
              }}
            />
          </Dialog>

          <Dialog
            visible={deleteEntityDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteEntitiyDialogFooter}
            onHide={hideDeleteEntityDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entity && (
                <span>
                  Voulez vous vraiment supprimer <b>{entity.username}</b>?
                </span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteEntitiesDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteEntitiesDialogFooter}
            onHide={hideDeletecountriesDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {entity && (
                <span>
                  Voulez vous vraiment supprimer les Elements sélectionnées?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default PostsList;
