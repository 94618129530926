import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import BasicCrudService from "../../service/BasicCrudService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FileUpload } from "primereact/fileupload";
import { BASE_URL } from "../../service/constant.service";
import { Image } from "primereact/image";
import axiosInstance from "../../service/ApiConfig.service";


const Countries = () => {
  const resourcePlural = "countries";
  let emptyCountry = {
    code: "",
    name: "",
  };
  const [countries, setCountries] = useState(null);
  const [image, setImage] = useState(null);
  const [countryDialog, setCountryDialog] = useState(false);
  const [deleteCountryDialog, setDeleteCountryDialog] = useState(false);
  const [deletecountriesDialog, setDeletecountriesDialog] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const crudService = new BasicCrudService();
  const [country, setCountry] = useState(emptyCountry);

  const formik = useFormik({
    initialValues: country,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object({
      code: Yup.string().required(),
      name: Yup.string().required(),
    }),
    onSubmit: (values) => {
      saveCountry(values);
    },
  });

  useEffect(() => {
    crudService
      .get(resourcePlural)
      .then((data) => {
        setCountries(data.data);
      })
      .catch((err) => {});
  }, []);

  const openNew = () => {
    setCountry(emptyCountry);
    setCountryDialog(true);
  };

  const hideDialog = () => {
    setCountryDialog(false);
  };

  const hideDeleteCountryDialog = () => {
    setDeleteCountryDialog(false);
  };

  const hideDeletecountriesDialog = () => {
    setDeletecountriesDialog(false);
  };

  const saveCountry = (values) => {
  
    if (image !== null) {
      const formData = new FormData();
      formData.append('files', image);
      
      axiosInstance.post('/upload', formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(({ data: createdFile }) => {
        persistEntity(values, createdFile)
        setImage(null);
      })
      .catch(e => setImage(null));
    }
    else {
      persistEntity(values);
    }
  };

  const persistEntity = (values, file = null) => {
    let _countries = [...countries];
    const dataToSend = !file ? {...values} : {...values, flag: file[0].id};

    if (country.id) {
      const index = findIndexById(country.id);

      crudService
        .update(resourcePlural, country.id, dataToSend)
        .then(({ data }) => {
          _countries[index] = data;
          setCountries(_countries);
          setCountryDialog(false);
          setCountry(emptyCountry);
          toast.current.show({
            severity: "success",
            summary: "Succès",
            detail: "pays mis à jour",
            life: 3000,
          });
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Erreur",
            detail: "Le formulaire contient des erreurs",
            life: 3000,
          });
        });
    } else {
      crudService
        .save(resourcePlural, dataToSend)
        .then((data) => {
          _countries.unshift(data.data);
          toast.current.show({
            severity: "success",
            summary: "Succès",
            detail: "pays enregistré",
            life: 3000,
          });
          setCountries(_countries);
          setCountryDialog(false);
          setCountry(emptyCountry);
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Erreur",
            detail: "Le formulaire contient des erreurs",
            life: 3000,
          });
        });
    }
  }

  const editCountry = (country) => {
    setCountry({ ...country.attributes, id: country.id });
    setCountryDialog(true);
  };

  const confirmDeleteCountry = (country) => {
    setCountry(country);
    setDeleteCountryDialog(true);
  };

  const deleteCountry = () => {
    crudService
      .delete(resourcePlural, country.id)
      .then((data) => {
        let _countries = countries.filter((val) => val.id !== country.id);
        setCountries(_countries);
        setDeleteCountryDialog(false);
        setCountry(emptyCountry);
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Pays supprimé",
          life: 3000,
        });
      })
      .catch((err) => {
        setCountry(emptyCountry);
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Impossible de supprimer le pays",
          life: 3000,
        });
      });
  };

  const findIndexById = (id) => {
    return countries.findIndex((item) => item.id === id);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeletecountriesDialog(true);
  };

  const deleteselectedCountries = () => {
    let _countries = countries.filter(
      (val) => !selectedCountries.includes(val)
    );
    setCountries(_countries);
    setDeletecountriesDialog(false);
    setSelectedCountries(null);
    toast.current.show({
      severity: "success",
      summary: "Succès",
      detail: " supprimés",
      life: 3000,
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Supprimer"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedCountries || !selectedCountries.length}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exporter"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editCountry(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => confirmDeleteCountry(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Gestion des pays</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const countryDialogFooter = (
    <>
      <Button
        label="Annuler"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Valider"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => formik.submitForm()}
      />
    </>
  );
  const deleteCountryDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteCountryDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteCountry}
      />
    </>
  );
  const deleteCountrysDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeletecountriesDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteselectedCountries}
      />
    </>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={countries}
            selection={selectedCountries}
            onSelectionChange={(e) => setSelectedCountries(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} éléments"
            globalFilter={globalFilter}
            emptyMessage="Aucun pays trouvé"
            header={header}
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple"></Column>
            <Column
              field="code"
              body={(data) => data.attributes.code}
              header="Code"
              sortable
            ></Column>
            <Column
              field="name"
              body={(data) => data.attributes.name}
              header="Nom"
              sortable
            ></Column>
            <Column
              field="flag"
              body={(data) => <img src={BASE_URL + data.attributes?.flag?.data?.attributes?.formats?.thumbnail?.url} alt=""/> }
              header="Drapeau"
              sortable
            ></Column>
            <Column
              field="createdAt"
              body={(data) => data.attributes.createdAt}
              header="Date de création"
              sortable
            ></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>

          <Dialog
            visible={countryDialog}
            style={{ width: "450px" }}
            header="Détails pays"
            modal
            className="p-fluid"
            footer={countryDialogFooter}
            onHide={hideDialog}
          >
            <div className="field">
              <label htmlFor="code">Code</label>
              <InputText
                id="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                required
                autoFocus
                className={classNames({
                  "p-invalid": formik.errors.code && formik.touched.code,
                })}
              />
              {formik.errors.code && formik.touched.code && (
                <small className="p-invalid">Ce champ est obligatoire.</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="name">Nom</label>
              <InputText
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                required
                rows={3}
                cols={20}
                className={classNames({
                  "p-invalid": formik.errors.name && formik.touched.name,
                })}
              />
              {formik.errors.name && formik.touched.name && (
                <small className="p-invalid">Ce champ est obligatoire.</small>
              )}
            </div>
            {formik?.values?.image?.data?.attributes?.url && (
              <Image
                src={`${BASE_URL}${formik?.values?.image?.data?.attributes?.url}`}
                zoomSrc={`${BASE_URL}${formik?.values?.image?.data?.attributes?.url}`}
                alt="Image"
                width="80"
                height="60"
                preview
              />
            )}
            <FileUpload
              name="upload"
              customUpload={true}
              mode="advanced"
              auto={true}
              chooseLabel="Fichier"
              accept="image/*"
              uploadHandler={(e) => {
                setImage(e.files[0]);
              }}
            />
          </Dialog>

          <Dialog
            visible={deleteCountryDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteCountryDialogFooter}
            onHide={hideDeleteCountryDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {country && (
                <span>
                  Voulez vous vraiment supprimer <b>{country.name}</b>?
                </span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deletecountriesDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteCountrysDialogFooter}
            onHide={hideDeletecountriesDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {country && (
                <span>
                  Voulez vous vraiment supprimer les pays sélectionnés?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Countries;
