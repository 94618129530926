import { Routes, Route } from "react-router-dom";
import ActorsList from "./ActorsList";

export default function Actors() {
  return (
    <Routes>
      <Route path="/" element={<ActorsList />} />
    </Routes>
  );
}
