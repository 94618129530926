import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import DeleteEntitiesDialog from "../../components/DeleteEntitiesDialog/DeleteEntitiesDialog";
import DeleteEntityDialog from "../../components/DeleteEntityDialog/DeleteEntityDialog";
import EntityActions from "../../components/EntityActions/EntityActions";
import DropdownField from "../../components/FormFields/DropdownField/DropdownField";
import FormField from "../../components/FormFields/FormField/FormField";
import Header from "../../components/Header/Header";
import { entitiesToSelectItems, getResources } from "../../lib";
import BasicCrudService from "../../service/BasicCrudService";
import { clearEmptyFields } from "../../utils";

const emptyEntity = {
  name: "",
  responsible_identity: "",
  responsible_address: "",
  responsible_phone: "",
  responsible_mail: "",
  pi: "",
};
export default function PiZoneList() {
  const resourcePlural = "pi-zones";

  const [entities, setEntities] = useState([]);
  const [pis, setPis] = useState([]);
  const [entityDialogIsOpen, setEntityDialogIsOpen] = useState(false);
  const [deleteEntityDialogIsOpen, setDeleteEntityDialogIsOpen] =
    useState(false);
  const [deleteEntitiesDialogIsOpen, setDeleteEntitiesDialogIsOpen] =
    useState(false);
  const [entity, setEntity] = useState(emptyEntity);
  const [selectedEntities, setSelectedEntities] = useState(null);
  const [_, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  const crudService = useMemo(() => {
    return new BasicCrudService();
  }, []);

  const formik = useFormik({
    initialValues: entity,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(),
      responsible_identity: Yup.string().required(),
      responsible_address: Yup.string().required(),
      responsible_phone: Yup.string().required(),
      responsible_mail: Yup.string().required(),
      pi: Yup.string().required(),
    }),

    onSubmit: async (values) => {
      values = clearEmptyFields(values);
      const isUpdate = Boolean(entity.id);

      try {
        const result = isUpdate
          ? await crudService.update("pi-zones", entity.id, values)
          : await crudService.save("pi-zones", values);

        if (isUpdate) {
          const index = findIndexById(entity.id);
          const _entities = entities;
          _entities[index] = result.data;
          setEntities(_entities);
        } else {
          entities.unshift(result.data);
        }

        formik.resetForm();

        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Zone sauvegardée avec succès",
          life: 3000,
        });

        setEntityDialogIsOpen(false);
      } catch (error) {
        console.error(error);
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Le formulaire contient des erreurs",
          life: 3000,
        });
      }
    },
  });

  useEffect(() => {
    getResources(["pis", "pi-zones"], (tasks) => {
      const [pis, pi_zones] = tasks;
      setEntities(pi_zones.data);
      setPis(pis.data);
    });
  }, []);

  const openNew = () => {
    setEntity(emptyEntity);
    setSubmitted(false);
    setEntityDialogIsOpen(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setEntityDialogIsOpen(false);
    formik.resetForm();
  };

  const hideEntityCountryDialog = () => {
    setDeleteEntityDialogIsOpen(false);
  };

  const hideDeleteEntitiesDialog = () => {
    setDeleteEntitiesDialogIsOpen(false);
  };

  const editEntity = (entity) => {
    setEntity({ ...entity });
    setEntityDialogIsOpen(true);
  };

  const confirmDeleteCountry = (entity) => {
    setEntity(entity);
    setDeleteEntityDialogIsOpen(true);
  };

  async function deleteEntity() {
    try {
      await crudService.delete(resourcePlural, entity.id);
      const _entities = entities.filter((val) => val.id !== entity.id);
      setEntities(_entities);
      setDeleteEntityDialogIsOpen(false);
      setEntity(emptyEntity);
      toast.current.show({
        severity: "success",
        summary: "Succès",
        detail: "Zone supprimée",
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erreur",
        detail: "Impossible de supprimer la zone",
        life: 3000,
      });
      console.error(error);
    }
  }

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < entities.length; i++) {
      if (entities[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteEntitiesDialogIsOpen(true);
  };

  const deleteselectedCountrys = () => {
    let _entities = entities.filter((val) => !selectedEntities.includes(val));
    setEntities(_entities);
    setDeleteEntitiesDialogIsOpen(false);
    setSelectedEntities(null);
    toast.current.show({
      severity: "success",
      summary: "Succès",
      detail: " supprimés",
      life: 3000,
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Supprimer"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedEntities || !selectedEntities.length}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          label="Importer"
          chooseLabel="Importer"
          className="mr-2 inline-block"
        />
        <Button
          label="Exporter"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (entity) => {
    return (
      <EntityActions
        actions={[
          {
            icon: "pi pi-pencil",
            className: "p-button-rounded mr-2",
            onClick: () => {
              if (entity.attributes.pi && entity.attributes.pi.data) {
                entity.attributes.pi = entity.attributes.pi.data.id;
              }
              entity.attributes = {
                ...entity.attributes,
              };
              entity.attributes.id = entity.id;
              editEntity(entity.attributes);
            },
          },
          {
            icon: "pi pi-trash",
            className: "p-button-rounded p-button-danger mt-2",
            onClick: () => {
              confirmDeleteCountry(entity);
            },
          },
        ]}
      />
    );
  };

  const userDialogFooter = (
    <>
      <Button
        label="Annuler"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Valider"
        icon="pi pi-check"
        className="p-button-text"
        type="button"
        onClick={formik.handleSubmit}
      />
    </>
  );
  const deleteEntityDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideEntityCountryDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteEntity}
      />
    </>
  );
  const deleteEntitiesDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteEntitiesDialog}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteselectedCountrys}
      />
    </>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />
          <DataTable
            ref={dt}
            value={entities}
            selection={selectedEntities}
            onSelectionChange={(e) => setSelectedEntities(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} éléments"
            globalFilter={globalFilter}
            emptyMessage="Aucune zone trouvée"
            header={
              <Header
                setGlobalFilter={setGlobalFilter}
                title="Gestion des zones"
              />
            }
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple" />
            <Column
              field="pi"
              body={(data) => {
                if (data?.attributes?.pi?.data) {
                  return data?.attributes?.pi?.data?.attributes?.label;
                } else {
                  if (data?.attributes?.pi) {
                    const pi = pis.find(
                      (pi) => pi?.id === data?.attributes?.pi
                    );
                    return pi?.attributes?.label;
                  }
                  return "";
                }
              }}
              header="PI"
              sortable
            ></Column>
            <Column
              field="code"
              body={(data) => data.attributes.name}
              header="Nom"
              sortable
            ></Column>
            <Column
              field="short_label"
              body={(data) => data.attributes.responsible_identity}
              header="Identité du responsable"
              sortable
            ></Column>
            <Column
              field="label"
              body={(data) => data.attributes.responsible_address}
              header="Adresse du responsable"
              sortable
            ></Column>
            <Column
              field="idea"
              body={(data) => data.attributes.responsible_phone}
              header="Téléphone du responsable"
              sortable
            ></Column>
            <Column
              field="open_date"
              body={(data) => data.attributes.responsible_mail}
              header="Email du responsable"
              sortable
            ></Column>
            <Column body={actionBodyTemplate} />
          </DataTable>
          <Dialog
            visible={entityDialogIsOpen}
            style={{ width: "700px" }}
            header={"Détails de la zone de couverture"}
            modal
            className="p-fluid"
            footer={userDialogFooter}
            onHide={hideDialog}
          >
            <div className="row">
              <FormField label="Nom" name="name" formik={formik} />
              <FormField
                label="Identité du responsable"
                name="responsible_identity"
                formik={formik}
              />
            </div>
            <div className="row">
              <FormField
                label="Adresse du responsable"
                name="responsible_address"
                formik={formik}
              />
              <FormField
                label="Téléphone du responsable"
                name="responsible_phone"
                formik={formik}
              />
            </div>
            <FormField
              label="Email du responsable"
              name="responsible_mail"
              formik={formik}
            />
            <DropdownField
              label="Plateformes d'innovation"
              name="pi"
              placeholder="Selectionner une plateforme d'innovation"
              options={entitiesToSelectItems(pis, "label")}
              formik={formik}
            />
          </Dialog>
          <DeleteEntityDialog
            isOpen={deleteEntityDialogIsOpen}
            footer={deleteEntityDialogFooter}
            hide={hideEntityCountryDialog}
          />
          <DeleteEntitiesDialog
            isOpen={deleteEntitiesDialogIsOpen}
            footer={deleteEntitiesDialogFooter}
            hide={hideDeleteEntitiesDialog}
          />
        </div>
      </div>
    </div>
  );
}
